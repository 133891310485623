import { ReactNode } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { Box } from '@mui/material';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import useSearchParams from 'src/hooks/use-search-params';
import clsx from 'clsx';
import { useStyles } from './styles';
import { LeftArrow, RightArrow } from './arrows';

interface HorizontalSelectOption {
  label: ReactNode;
  key: string;
}

interface HorizontalSelectProps {
  options: Partial<HorizontalSelectOption>[];
  onSelect?: (keys: string[]) => void;
  multiple?: boolean;
  selectAllKey?: string;
  name: string;
}

const HorizontalSelect = ({
  options,
  onSelect,
  multiple,
  selectAllKey = 'allEvents',
  name,
}: HorizontalSelectProps) => {
  const { classes } = useStyles();
  const { searchParams, setSearchParam } = useSearchParams();
  const selected = searchParams.get(name)?.split(',') ?? [selectAllKey];

  const handleSelect = (key: string) => () => {
    if (key === selectAllKey && !selected.includes(selectAllKey)) {
      setSearchParam({ [name]: null });
      return;
    }

    if (selected.includes(key)) {
      const newSelection = selected.filter((selecteKey) => selecteKey !== key);
      setSearchParam({ [name]: newSelection.length > 0 ? newSelection : null });
      return;
    }

    const selection = multiple
      ? [...selected.filter((selecteKey) => selecteKey !== selectAllKey), key]
      : [key];

    setSearchParam({ [name]: selection });

    if (typeof onSelect === 'function') {
      onSelect(selection);
    }
  };

  return (
    <ScrollMenu
      wrapperClassName={classes.horizontalSelect}
      LeftArrow={LeftArrow}
      RightArrow={RightArrow}
    >
      {options.map(({ label, key }) => (
        <Box
          key={key}
          onClick={handleSelect(key)}
          className={clsx({
            [classes.topic]: true,
            [classes.active]: selected.includes(key),
          })}
        >
          {label}
        </Box>
      ))}
    </ScrollMenu>
  );
};

export default HorizontalSelect;
