// @ts-nocheck
import React, { useEffect } from 'react';
import clsx from 'clsx';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './videoPlayer.css';

interface VideoJSProps {
  width?: number;
  height?: number;
  url?: string;
  fluid?: boolean;
  autoplay?: boolean;
  onPlay?: () => void;
  className?: string;
  poster?: string;
}

const VideoJS = ({
  width,
  height,
  url,
  fluid,
  autoplay,
  onPlay,
  poster,
  onEnded,
  className,
}: VideoJSProps) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);

  useEffect(() => {
    const preparePlayer = async () => {
      if (!playerRef.current) {
        const videoElement = videoRef.current;
        if (!videoElement) return;

        playerRef.current = videojs(videoElement, {
          sources: {
            type: 'application/x-mpegURL',
            src: url,
          },
          fluid,
          autoplay,
          poster,
        });
      }
    };

    preparePlayer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <video
        width={width}
        height={height}
        autoPlay={false}
        controls
        ref={videoRef}
        className={clsx('video-js', 'vjs-big-play-centered', className)}
        onPlay={onPlay}
        onEnded={onEnded}
      />
    </div>
  );
};

export default VideoJS;
