import React, { useState } from 'react';
import clsx from 'clsx';
import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import MenuCheckIcon from 'src/assets/menu-check.svg?react';
import useSearchParams from 'src/hooks/use-search-params';
import { Filters, FiltersRecord } from 'src/utils/analytic-events';
import { useStyles } from './styles';
import ChevronDownSVG from './assets/chevron-down.svg?react';

export interface SelectOption {
  name: string;
  value: string;
  hidden?: boolean;
}

interface SelectInputProps {
  name: Filters;
  onChange: (
    param: FiltersRecord | React.ChangeEvent<HTMLInputElement>,
  ) => void;
  options: SelectOption[];
  multiple?: boolean;
  selectAll?: {
    name: string;
    value: string;
  };
  dark?: boolean;
  defaultValue?: string;
  paperWidth?: number;
  label?: string;
}

export const OptionCheckmark = ({ isSelected }) => {
  const { classes } = useStyles({});
  return (
    <MenuCheckIcon
      className={clsx({
        [classes.checkIcon]: true,
        [classes.show]: isSelected,
      })}
    />
  );
};

const SelectInput = ({
  name,
  options,
  onChange,
  multiple,
  selectAll,
  dark,
  defaultValue,
  paperWidth,
  label,
}: SelectInputProps) => {
  const ref = React.useRef<any>(null);
  const [open, setOpen] = useState(false);
  const { searchParams, setSearchParam } = useSearchParams();
  const selectionParam = searchParams.get(name);
  const selection = multiple
    ? selectionParam?.split(',') ?? [defaultValue]
    : selectionParam ?? defaultValue;
  const { classes } = useStyles({ open, dark, paperWidth });

  const handleOpen = () => {
    setOpen(true);
    ref.current.classList.remove('Mui-focused');
  };

  const handleClose = () => {
    setOpen(false);
    ref.current.classList.remove('Mui-focused');
  };

  const handleChange = (event: SelectChangeEvent<any>) => {
    const newSelection = event.target.value;

    if (newSelection?.length === 0) {
      setSearchParam({ [name]: null });
      return;
    }

    if (multiple) {
      let overrideSelection = newSelection;
      const hasAllOptionsSelected = options.every((option) =>
        newSelection.includes(option.value),
      );

      if (hasAllOptionsSelected) {
        overrideSelection = [...newSelection, selectAll?.value];
      }

      if (newSelection?.includes(selectAll?.value)) {
        const isLastSelectionAll = newSelection?.at(-1) === selectAll?.value;

        const selectAllDeselection = newSelection.filter(
          (key) => key !== selectAll?.value,
        );

        overrideSelection = isLastSelectionAll ? null : selectAllDeselection;
      }

      setSearchParam({ [name]: overrideSelection });

      if (typeof onChange === 'function') {
        onChange({ [name]: overrideSelection?.toString() });
      }

      return;
    }

    setSearchParam({ [name]: newSelection });
    if (typeof onChange === 'function') {
      onChange(event);
    }
  };

  const selectOptions = [...(selectAll ? [selectAll] : []), ...options];

  return (
    <Select
      ref={ref}
      name={name}
      value={selection}
      onChange={handleChange}
      className={clsx(classes.filterSelect, classes.bgColor)}
      onOpen={handleOpen}
      onClose={handleClose}
      IconComponent={() => <ChevronDownSVG />}
      displayEmpty
      classes={{
        select: clsx({
          [classes.selected]: multiple
            ? selection?.some((key) => key !== selectAll?.value)
            : !!selection,
        }),
      }}
      sx={{
        boxShadow: 'none',
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
      }}
      multiple={multiple}
      MenuProps={{ classes: { paper: classes.paper } }}
      renderValue={(selected) => {
        if (multiple && !selected.includes(selectAll?.value)) {
          const multiSelectionLabel = `${label} (${selected.length})`;
          return multiSelectionLabel;
        }

        const singleSelectionLabel = selectOptions?.find(
          (option) => option.value === (multiple ? selected?.at(-1) : selected),
        )?.name;

        return singleSelectionLabel;
      }}
    >
      {selectOptions.map((option: SelectOption) => {
        const isSelected = multiple
          ? selection!.includes(option.value)
          : option.value === selection;

        return (
          <MenuItem
            key={option.name}
            value={option.value}
            className={clsx({
              [classes.menuItem]: true,
              [classes.selected]: isSelected,
              [classes.hidden]: option.hidden,
            })}
          >
            {option.name}
            <OptionCheckmark isSelected={isSelected} />
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default SelectInput;
