import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import VideoJS from 'src/components/shared/video-player/video-player';
import useStyles from './styles';

interface SectionContentProps {
  topicKey: string;
  videoKey: string;
}
const SectionContent = ({ topicKey, videoKey }: SectionContentProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  // const { VITE_LANDING_URL } = import.meta.env;
  // use only production as there is no staging content
  const VITE_LANDING_URL = 'https://join.likeminded.care';
  const urlPath = `${VITE_LANDING_URL}/content/videos/bad/${videoKey}`;

  return (
    <Box className={classes.sectionContent}>
      <ul>
        {JSON.parse(
          t(`v2.badHotline.drawer_learnMore_descriptionList_${topicKey}`, {
            returnObjects: true,
          }),
        ).map((item: string) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
      <VideoJS
        url={`${urlPath}/source.m3u8`}
        poster={`${urlPath}/poster.jpg`}
        fluid
      />
    </Box>
  );
};

export default SectionContent;
