import { FC, ReactNode, UIEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Drawer,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import IconBack from 'src/assets/back.svg?react';
import { useStyles } from './styles';
import DrawerFooter from './drawer-footer';
import CloseButton from './close-button';

interface SideDrawerProps {
  onClose: () => void;
  toggleDrawer: boolean;
  children: ReactNode;
  ctaLabel?: string;
  showCta?: boolean;
  showBack?: boolean;
  ctaDisabled?: boolean;
  onConfirm?: () => void;
  onBack?: () => void;
  onScroll?: (event: UIEvent<HTMLDivElement>) => void;
  ctaSecondary?: ReactNode;
  ctaSecondaryCallback?: () => void;
  onShowPrivacyDrawer?: () => void;
  footer?: ReactNode;
  ctaWrapperClassName?: string;
}

const SideDrawer: FC<SideDrawerProps> = ({
  onClose,
  toggleDrawer,
  children,
  onBack,
  showBack = false,
  onScroll,
  footer,
  ctaWrapperClassName,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <>
      <Drawer
        anchor={isMobile ? 'bottom' : 'right'}
        open={toggleDrawer}
        onClose={onClose}
        PaperProps={{
          onScroll,
          id: 'side-drawer',
        }}
        ModalProps={{
          keepMounted: false,
        }}
      >
        <Box className={classes.drawerHeader}>
          {showBack ? (
            <Box className={classes.backBtnWrapper}>
              <IconButton
                color="inherit"
                onClick={() => onBack && onBack()}
                size="large"
              >
                <IconBack className={classes.icon} />
                <Typography variant="buttonDefault">
                  {t('v2.global.button_label_goBack')}
                </Typography>
              </IconButton>
            </Box>
          ) : (
            <CloseButton onClose={onClose} />
          )}
        </Box>

        <Box id="drawer" className={classes.content}>
          {children}
        </Box>

        {footer && (
          <DrawerFooter
            footer={footer}
            ctaWrapperClassName={ctaWrapperClassName}
          />
        )}
      </Drawer>
    </>
  );
};

export default SideDrawer;
