import { captureException } from '@sentry/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertContext } from 'src/app/alert-provider';
import i18n from 'src/translations/i18n';
import {
  isMobile,
  isTablet,
  deviceType,
  osName,
  osVersion,
} from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { selectCommon } from 'src/store/common';
import { useSelector } from 'react-redux';
import { recordSessionReplay } from './analytics-api';
import { queryClient } from './config/react-query';
import { getUser } from './users-api';
import cache from './config/cache';

type CognitoErrorType =
  | 'UserNotFoundException'
  | 'NotAuthorizedException'
  | 'UserNotConfirmedException'
  | 'UsernameExistsException'
  | 'UserLambdaValidationException'
  | 'ExpiredCodeException'
  | 'LimitExceededException';

interface UseAuthenticateUserProps {
  onUnverified: () => void;
}

interface AuthenticateUserProps {
  email: string;
  password: string;
}

const fetchAuthUser = async () => {
  const data = await Auth.currentAuthenticatedUser();

  return data;
};

const reloadAuthUser = () => {
  queryClient.invalidateQueries(['authUser']);
};

const authenticateUser = async ({ email, password }: AuthenticateUserProps) => {
  const result = await Auth.signIn(email, password);
  const user = await getUser();

  if (window.analytics && user) {
    window.analytics.identify(user.id, {
      language: i18n.language,
      deviceType: isMobile ? 'Mobile' : isTablet ? 'Tablet' : 'Desktop',
      deviceModel: `${deviceType} ${osVersion}`,
      osName,
      osVersion,
    });

    recordSessionReplay({
      deviceId: localStorage.getItem('ajs_user_id') || crypto.randomUUID(),
      sessionId: localStorage.getItem('analytics_session_id')
        ? Number(localStorage.getItem('analytics_session_id'))
        : Date.now(),
    });
  }

  return result;
};

const resendSignUp = async (email: string) => {
  const result = await Auth.resendSignUp(email);
  return result;
};

export const useGetAuthUser = () => {
  const query = useQuery(['authUser'], fetchAuthUser, {
    staleTime: cache.INFINITY,
    select: (data) => ({
      ...data,
      roles: data.signInUserSession?.accessToken?.payload['cognito:groups'],
    }),
    retry: false,
  });

  return {
    ...query,
    reloadAuthUser,
  };
};

export const useAuthenticateUser = ({
  onUnverified,
}: UseAuthenticateUserProps) => {
  const { showAlert } = useContext(AlertContext);
  const { t } = useTranslation();
  const { redirectUrl } = useSelector(selectCommon);
  const navigate = useNavigate();

  return useMutation(authenticateUser, {
    onSettled: () => queryClient.invalidateQueries(['authUser']),
    onSuccess: (data) => {
      const roles =
        data?.signInUserSession?.accessToken?.payload?.['cognito:groups'];
      const home = roles?.includes('company_admin') ? '/company-admin' : '/';
      const pathname = redirectUrl?.pathname || home;
      const search = redirectUrl?.search || '';

      navigate({ pathname, search }, { replace: true });

      showAlert({
        severity: 'success',
        message: t('Login.successMsg'),
      });
    },

    onError: (error: { code: CognitoErrorType }) => {
      if (error.code === 'UserNotConfirmedException') {
        onUnverified();
        return;
      }

      if (
        ['NotAuthorizedException', 'UserNotFoundException'].includes(error.code)
      ) {
        showAlert({
          severity: 'error',
          message: t('v2.global.snackbar_label_error_incorrectPasswordOrName'),
        });
        return;
      }

      captureException(error);
      showAlert({
        severity: 'error',
        message: t('Login.notAllowed'),
      });
    },
  });
};

export const useResendEmail = () => {
  const { showAlert } = useContext(AlertContext);
  const { t } = useTranslation();

  return useMutation(resendSignUp, {
    onSuccess: () => {
      showAlert({
        severity: 'success',
        message: t('v2.verification.snackbar_label_verificationEmailSentAgain'),
      });
    },
    onError: () => {
      showAlert({
        severity: 'error',
        message: t('v2.global.snackbar_label_error_somethingWentWrong'),
      });
    },
  });
};

export const useLogout = () => {
  const { showAlert } = useContext(AlertContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const logout = async () => {
    await Auth.signOut();
    localStorage.clear();
    queryClient.removeQueries();
    showAlert({
      severity: 'success',
      message: t('Logout.successMsg'),
    });
    navigate('/login');
  };

  return logout;
};
