import { ReactNode } from 'react';
import {
  MediaController,
  MediaControlBar,
  MediaTimeDisplay,
  MediaPlayButton,
  MediaMuteButton,
  MediaVolumeRange,
  MediaTimeRange,
} from 'media-chrome/dist/react';
import PlayIcon from 'src/assets/audio-play.svg?react';
import PauseIcon from 'src/assets/audio-pause.svg?react';
import AudioVolumeOffIcon from 'src/assets/audio-volume-off.svg?react';
import AudioVolumeMediumIcon from 'src/assets/audio-volume-medium.svg?react';
import AudioVolumeHighIcon from 'src/assets/audio-volume-high.svg?react';
import useStyles from './styles';

interface MediaChromeSkinProps {
  children: ReactNode;
}

const MediaChromeSkin = ({ children }: MediaChromeSkinProps) => {
  const { classes } = useStyles();

  return (
    <MediaController audio className={classes.mediaController}>
      {children}
      <MediaControlBar>
        <MediaPlayButton>
          <PlayIcon slot="play" />
          <PauseIcon slot="pause" />
        </MediaPlayButton>
        <MediaTimeDisplay showduration />
        <MediaTimeRange />
        <MediaMuteButton>
          <AudioVolumeOffIcon slot="off" />
          <AudioVolumeMediumIcon slot="low" />
          <AudioVolumeMediumIcon slot="medium" />
          <AudioVolumeHighIcon slot="high" />
        </MediaMuteButton>
        <MediaVolumeRange />
      </MediaControlBar>
    </MediaController>
  );
};

export default MediaChromeSkin;
