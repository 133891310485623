import { BookingSource } from 'src/apis/bookings-api';
import useAppDrawer from 'src/app/use-app-drawer';
import GroupSessionsInfo from './group-sessions/group-sessions';
import MentorsInfo from './mentors/mentors';

const useSectionInfo = (
  type: 'GROUP_SESSIONS_EXPLANATION' | 'INDIVIDUAL_SESSIONS_EXPLANATION',
  source: BookingSource,
) => {
  const content = {
    GROUP_SESSIONS_EXPLANATION: <GroupSessionsInfo />,
    INDIVIDUAL_SESSIONS_EXPLANATION: <MentorsInfo />,
  }[type];

  return useAppDrawer({
    params: ['info'],
    value: type,
    content,
    track: {
      properties: { location: window.location.href, type, source },
    },
  });
};

export default useSectionInfo;
