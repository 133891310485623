import { analyticsTrack } from 'src/apis/analytics-api';

export type AnalyticsEventName =
  | 'Onboarding step completed'
  | 'Onboarding step dismissed'
  | 'Alert Banner Clicked'
  | 'Alert Banner Dismissed'
  | 'Assessment Started'
  | 'Assessment Step Completed'
  | 'BAD Hotline Clicked'
  | 'Widget Status Changed'
  | 'Recommendation dismissed'
  | 'Exercise Link Opened'
  | 'Video Played'
  | 'Video Ended'
  | 'Audio Played'
  | 'Audio Ended'
  | 'Reading Opened'
  | 'Product Recommendation Reminder Submitted'
  | 'Book Based on Availability Card Clicked'
  | 'Drawer Opened'
  | 'Filters Reset'
  | 'Results Filtered'
  | 'Link Shared';

export type TrackProperties =
  | TypeEventProperties
  | BadHotlineClickedProperties
  | WidgetStatusChangedProperties
  | RecommendationDismissedProperties
  | BookBasedOnAvailabilityCardClickedProperties
  | LibraryDrawerEventProperties
  | AssessmentStartedProperties
  | AssessmentStepCompletedProperties
  | DrawerOpenedProperties
  | FiltersProperties;

type PropertyType =
  | 'buyCredits'
  | 'seeRecommendation'
  | 'learnMoreAboutFormats'
  | 'preferences'
  | 'recommendationsNoMatch'
  | 'subscribeToNewsletter'
  | 'wellbeingResult'
  | 'freeSession'
  | 'dismissed'
  | 'expanded'
  | 'collapsed'
  | 'Bad Hotline Drawer'
  | 'Emergency Contact Drawer'
  | 'Individual Session Drawer'
  | 'Privacy Drawer'
  | 'Group Session Drawer'
  | 'Recommendation Dismissed'
  | 'GROUP_SESSIONS_EXPLANATION'
  | 'INDIVIDUAL_SESSIONS_EXPLANATION'
  | 'Self Pay Drawer'
  | 'Wellbeing details Drawer'
  | 'SPARK_PREFERENCES'
  | 'WEBAPP_ONBOARDING'
  | 'SPARK_ONBOARDING'
  | 'SPARK_WELLBEING'
  | 'Library Drawer';

interface TypeEventProperties {
  type: PropertyType;
}

interface BadHotlineClickedProperties {
  from: 'Button' | 'Phone Number';
}

interface WidgetStatusChangedProperties {
  status: 'dismissed';
}

interface RecommendationDismissedProperties {
  id: string;
  format?: string;
  feedback: string;
}
interface BookBasedOnAvailabilityCardClickedProperties {
  source?: string;
}

interface AssessmentStartedProperties extends TypeEventProperties {
  origin?: string;
}

interface AssessmentStepCompletedProperties extends TypeEventProperties {
  step: string;
}

export interface DrawerOpenedProperties extends TypeEventProperties {
  id: string;
  source?: string;
  recommended?: boolean;
  ranking?: number;
  name: string;
  languages?: string[];
  focusTopics?: string[];
  language?: string;
  duration?: string;
  itemType?: string;
  mentorName?: string;
  starter?: boolean;
  isNew?: boolean;
  topic?: string;
  category?: string;
  search?: string | null;
}

interface LibraryDrawerEventProperties extends TypeEventProperties {
  name: string;
  topic?: string;
  language: string;
  isNew: boolean;
  itemType: 'meditation' | 'exercise' | 'reading' | 'recording';
  duration?: number;
  category?: string;
  ranking?: number;
  url?: string;
  currentTimeSeconds?: number;
  durationSeconds?: number;
}

export type Filters =
  | 'type'
  | 'topics'
  | 'search'
  | 'language'
  | 'format'
  | 'duration'
  | 'time'
  | 'categories'
  | 'education'
  | 'gender';

export type FiltersRecord = Partial<Record<Filters, string>>;

type FiltersProperties = FiltersRecord & {
  location: string;
};

export type TrackAnalytics = (params: TypeEventProperties) => void;
export const analyticEvents = {
  alertBannerClicked: (properties: TypeEventProperties) => {
    analyticsTrack({
      name: 'Alert Banner Clicked',
      properties,
    });
  },
  alertBannerDismissed: (properties: TypeEventProperties) => {
    analyticsTrack({
      name: 'Alert Banner Dismissed',
      properties,
    });
  },
  onboardingStepCompleted: (properties: TypeEventProperties) => {
    analyticsTrack({
      name: 'Onboarding step completed',
      properties,
    });
  },
  onboardingStepDismissed: (properties: TypeEventProperties) => {
    analyticsTrack({
      name: 'Onboarding step dismissed',
      properties,
    });
  },
  badHotlineClicked: (properties: BadHotlineClickedProperties) => {
    analyticsTrack({
      name: 'BAD Hotline Clicked',
      properties,
    });
  },
  widgetStatusChanged: (properties: WidgetStatusChangedProperties) => {
    analyticsTrack({
      name: 'Widget Status Changed',
      properties,
    });
  },
  recommendationDismissed: (properties: RecommendationDismissedProperties) => {
    analyticsTrack({
      name: 'Recommendation dismissed',
      properties,
    });
  },
  exerciseLinkOpened: (properties: LibraryDrawerEventProperties) => {
    analyticsTrack({
      name: 'Exercise Link Opened',
      properties,
    });
  },
  videoPlayed: (properties: LibraryDrawerEventProperties) => {
    analyticsTrack({
      name: 'Video Played',
      properties,
    });
  },
  videoEnded: (properties: LibraryDrawerEventProperties) => {
    analyticsTrack({
      name: 'Video Ended',
      properties,
    });
  },
  audioPlayed: (properties: LibraryDrawerEventProperties) => {
    analyticsTrack({
      name: 'Audio Played',
      properties,
    });
  },
  audioEnded: (properties: LibraryDrawerEventProperties) => {
    analyticsTrack({
      name: 'Audio Ended',
      properties,
    });
  },
  readingOpened: (properties: LibraryDrawerEventProperties) => {
    analyticsTrack({
      name: 'Reading Opened',
      properties,
    });
  },
  productRecommendationReminderSubmitted: (properties: TypeEventProperties) => {
    analyticsTrack({
      name: 'Product Recommendation Reminder Submitted',
      properties,
    });
  },
  bookBasedOnAvailabilityCardClicked: (
    properties: BookBasedOnAvailabilityCardClickedProperties,
  ) => {
    analyticsTrack({
      name: 'Book Based on Availability Card Clicked',
      properties,
    });
  },
  drawerOpened: (properties: DrawerOpenedProperties) => {
    analyticsTrack({
      name: 'Drawer Opened',
      properties,
    });
  },

  assessmentStepCompleted: (properties: AssessmentStepCompletedProperties) => {
    analyticsTrack({
      name: 'Assessment Step Completed',
      properties,
    });
  },
  assessmentStarted: (properties: AssessmentStartedProperties) => {
    analyticsTrack({
      name: 'Assessment Started',
      properties,
    });
  },
  filtersReset: (properties: FiltersProperties) => {
    analyticsTrack({
      name: 'Filters Reset',
      properties,
    });
  },
  resultsFiltered: (properties: FiltersProperties) => {
    analyticsTrack({
      name: 'Results Filtered',
      properties,
    });
  },
  linkShared: (properties: TypeEventProperties) => {
    analyticsTrack({
      name: 'Link Shared',
      properties,
    });
  },
};
