import { useQuery, useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { addToastInfo } from 'src/store/common';
import i18n from 'src/translations/i18n';
import { captureException } from '@sentry/react';
import { queryClient } from './config/react-query';
import backendApi from './config/backend';
import cache from './config/cache';

export interface LastAssessmentApi {
  id: string;
  createdAt: string;
  updatedAt: string;
  userId: string;
  assessmentResponseId: string;
  submittedAt: string;
  who5Score: number;
  phq4AnxietyScore: number;
  phq4DepressionScore: number;
  stressScore: number;
  burnoutScore?: number;
}

interface OfferingSubjectRating {
  offeringSubjectId: string;
  averageRating: number;
  totalBookings: number;
}
type QuestionReference =
  | 'QUESTION_GOALS'
  | 'QUESTION_TOPICS'
  | 'QUESTION_LANGUAGES'
  | 'QUESTION_GENDER';
interface OnboardingQuestion {
  question: QuestionReference;
  options: string[];
}

interface OnboardingQuestionsPayload {
  onboardingQuestions: OnboardingQuestion[];
}
type OnboardingFormData = Partial<Record<QuestionReference, string[]>>;

const transformToApiFormatNewOnboarding = (
  onboardingFormData: OnboardingFormData,
): OnboardingQuestion[] => {
  return Object.entries(onboardingFormData).map(([question, options]) => ({
    question: question as QuestionReference,
    options,
  }));
};

const postNewOnboardingQuestions = async (payload: OnboardingFormData) => {
  const transformData: OnboardingQuestionsPayload = {
    onboardingQuestions: transformToApiFormatNewOnboarding(payload),
  };
  const result = await backendApi.post(
    '/assessment/webapp-onboarding',
    transformData,
  );
  return result.data;
};

export const usePostNewOnboardingQuestions = () => {
  const dispatch = useDispatch();
  return useMutation(postNewOnboardingQuestions, {
    onSuccess: () => {
      queryClient.invalidateQueries(['newOnboardingQuestions']);
    },
    onError: (error) => {
      captureException(error);
      dispatch(
        addToastInfo({
          toastInfo: {
            open: true,
            type: 'error',
            content: i18n.t('UseApi.UnexpectedError'),
          },
        }),
      );
    },
  });
};

const getLastAssessment = async () => {
  const result = await backendApi.get(`/assessment/wellbeing/last-result`);
  return result.data;
};

const getOfferingSubjectRatings = async (): Promise<OfferingSubjectRating> => {
  const result = await backendApi.get(
    `/assessment/user-feedback/offering-subject-ratings`,
  );
  return result.data;
};

const getWellbeingtHistory = async (months: number) => {
  const result = await backendApi.get(
    `/assessment/wellbeing/history?months=${months?.toString()}`,
  );
  return result.data;
};

export const useGetWellbeingHistory = ({ months }: { months: number }) =>
  useQuery(['wellbeingHistory', months], () => getWellbeingtHistory(months));

export const getWellbeingDimensions = (lastAssessment: LastAssessmentApi) => ({
  mentalWellness: lastAssessment?.who5Score || 0,
  calmness: 6 - lastAssessment?.phq4AnxietyScore || 0,
  joyfulMindset: 6 - lastAssessment?.phq4DepressionScore || 0,
  stressResilience: 5 - lastAssessment?.stressScore || 0,
  ...(lastAssessment?.burnoutScore && {
    burnout: 7 - lastAssessment?.burnoutScore / 10,
  }),
});

export const useGetLastAssessment = () =>
  useQuery({
    queryKey: ['assessmentScores'],
    queryFn: getLastAssessment,
    select: (data) => ({
      ...data,
      ...getWellbeingDimensions(data),
    }),
  });

export const useGetOfferingSubjectRatings = () => {
  return useQuery<OfferingSubjectRating[]>({
    queryKey: ['offeringSubjectRatings'],
    queryFn: getOfferingSubjectRatings,
    staleTime: cache.ONE_HOUR,
  });
};
