import { makeStyles } from 'tss-react/mui';

interface StyleProps {
  active?: boolean;
}

export const useStyles = makeStyles<StyleProps>()(
  (theme, { active }, classes) => ({
    menuList: {
      marginTop: theme.spacing(3),
    },
    logo: {
      width: 84,
      height: 24,
    },
    menuItem: {
      position: 'relative',
      marginBottom: theme.spacing(5),
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(1.5),
      },
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: theme.colors.lightGreen,
          borderRadius: 8,
        },
      },
    },
    menuItemActive: {
      backgroundColor: theme.colors.blueGreen,
      borderRadius: 8,
    },
    menuIcon: {
      padding: 6,
      borderRadius: 0,
      '& path': {
        fill: theme.colors.darkestGreen,
      },
    },
    new: {
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 2,
        right: 2,
        width: 8,
        height: 8,
        borderRadius: '100%',
        backgroundColor: theme.colors.curiousBlue,
      },
    },
    logoutIcon: {
      padding: theme.spacing(1),
      backgroundColor: theme.colors.gainsboro,
    },
    menuLink: {
      textAlign: 'center',
      display: 'block',
      fontSize: 16,
      lineHeight: '24px',
      [theme.breakpoints.up('sm')]: {
        fontSize: 14,
        lineHeight: '22px',
      },
      fontWeight: 'bold',
      textDecoration: 'none',
      color: theme.colors.darkestGray,
      [`&:not(.${classes.menuLinkActive}):hover`]: {
        [`&.${classes.logoutIcon}`]: {
          backgroundColor: theme.colors.darkestGray,
        },
      },
    },
    menuLinkActive: {
      '& path': {
        fill: theme.colors.darkestGreen,
      },
    },

    menuLabel: {
      fontWeight: active ? 'bold' : 'normal',
      color: theme.colors.darkestGreen,
    },
    menuButton: {
      display: 'grid',
      justifyContent: 'center',
    },
  }),
);
