import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetBookingHistory, useGetBookings } from 'src/apis/bookings-api';
import { useGetUser } from 'src/apis/users-api';
import { useEffect } from 'react';
import { isAfterDays } from 'src/utils/date-utils';
import { addToastInfo } from 'src/store/common';
import { useTranslation } from 'react-i18next';

interface UseAfterBookingProps {
  onClose?: () => void;
}

const isCalendlyScheduledEvent = (event: any) => {
  return (
    event.data.event &&
    event.data.event.indexOf('calendly') === 0 &&
    event.data.event === 'calendly.event_scheduled'
  );
};

const useAfterBooking = ({ onClose }: UseAfterBookingProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: user } = useGetUser();
  const { refetch: refetchMyBookings } = useGetBookings();
  const wellbeingAssessmentAt =
    user?.lastAssessment?.SPARK_WELLBEING?.submittedAt;
  const onboardingAssessmentAt =
    user?.lastAssessment?.SPARK_ONBOARDING?.submittedAt;
  const { refetch: refetchUser } = useGetUser();
  const { t } = useTranslation();
  const { refetch: refetchBookingHistory } = useGetBookingHistory();

  const handleBook = (event?: any) => {
    const calendlyInviteeUri = event?.data?.payload?.invitee?.uri ?? '';

    const showPreAssessment =
      isAfterDays({ fromDate: wellbeingAssessmentAt, days: 14 }) &&
      isAfterDays({ fromDate: onboardingAssessmentAt, days: 14 });

    const { pathname, state } = showPreAssessment
      ? {
          pathname: '/pre-assessment',
          state: { calendlyInviteeUri },
        }
      : {
          pathname: '/booking-confirmation?',
          state: { delay: 3000 },
        };
    const toastInfo = {
      open: true,
      type: 'success',
      content: t('v2.global.snackbar_label_successfulBooking'),
    };

    if (typeof onClose === 'function') {
      onClose();
    }

    refetchUser();
    refetchBookingHistory();
    refetchMyBookings();

    dispatch(addToastInfo({ toastInfo }));
    navigate(pathname, { state });
    localStorage.setItem('HAS_NEW_BOOKING', 'true');
  };

  useEffect(() => {
    const onMessage = (e: any) => {
      if (isCalendlyScheduledEvent(e) && user) {
        handleBook(e);
      }
    };

    window.addEventListener('message', onMessage);

    return () => window.removeEventListener('message', onMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { handleBook };
};

export default useAfterBooking;
