import { Box, Typography } from '@mui/material';
import { EventType } from 'src/types';
import VideoJS from 'src/components/shared/video-player/video-player';
import Accordion from 'src/components/shared/expansion-panel/expansion-panel';
import { getEventLabel } from 'src/utils/event-type';
import OfferingFormatTags from './offering-format-tags';
import { useStyles } from './styles';

interface OfferingFormatContentProps {
  credits?: number;
  sessions: number;
  duration: number;
  descriptionItems: string[];
  videoUrl?: string;
}

interface OfferingFormatDetailsProps {
  icon?: React.ReactNode;
  title?: string;
  defaultOpen?: boolean;
  className?: string;
  offeringFormatDetails: {
    offeringFormat: EventType;
    descriptionItems: string[];
    duration: number;
    videoUrl: string;
    sessions: number;
  };
}

interface OfferingFormatTitleProps {
  offeringFormat: EventType;
  title?: string;
}

export const OfferingFormatContent = ({
  credits,
  sessions,
  duration,
  descriptionItems,
  videoUrl,
}: OfferingFormatContentProps) => {
  const { classes } = useStyles();

  return (
    <>
      <OfferingFormatTags
        credits={credits}
        sessions={sessions}
        duration={duration}
      />
      <ul>
        {descriptionItems?.map((item: string) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
      {videoUrl && (
        <Box className={classes.videoContainer}>
          <VideoJS url={videoUrl} width={545} height={292} />
        </Box>
      )}
    </>
  );
};

const OfferingFormatTitle = ({
  offeringFormat,
  title,
}: OfferingFormatTitleProps) => {
  const { classes } = useStyles();
  return (
    <Typography className={classes.expansionPanelTitle}>
      {title ?? getEventLabel(offeringFormat)}
    </Typography>
  );
};

const OfferingFormatDetails = ({
  offeringFormatDetails,
  title,
  defaultOpen,
  icon,
  className,
}: OfferingFormatDetailsProps) => {
  return (
    <Accordion
      className={className}
      title={
        <OfferingFormatTitle
          offeringFormat={offeringFormatDetails?.offeringFormat}
          title={title}
        />
      }
      content={<OfferingFormatContent {...offeringFormatDetails} />}
      defaultOpen={defaultOpen}
      icon={icon}
    />
  );
};

export default OfferingFormatDetails;
