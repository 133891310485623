import { Box, LinearProgress, Typography } from '@mui/material';
import clsx from 'clsx';
import useStyle from './style';

interface ProgressBarProps {
  customClasses?: Record<string, string>;
  progressValue: number;
  progressLabel: string;
}

const LinearProgressBar: React.FC<ProgressBarProps> = ({
  customClasses = {},
  progressValue,
  progressLabel,
}) => {
  const { classes } = useStyle();
  return (
    <Box className={classes.progress}>
      <Typography variant="buttonSmall">{progressLabel}</Typography>
      <LinearProgress
        variant="determinate"
        value={progressValue}
        className={clsx(classes.progressBar, customClasses.progressBar)}
      />
    </Box>
  );
};

export default LinearProgressBar;
