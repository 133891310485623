import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import Accordion from 'src/components/shared/expansion-panel/expansion-panel';
import AvatarItem from 'src/components/shared/avatar-item/avatar-item';
import { Mentor } from 'src/apis/mentors-api';
import EducationSVG from './assets/education.svg?react';
import { useStyles } from './styles';

interface CareerAndEducationProps {
  mentor: Mentor;
}
interface SectionProps {
  title: string;
  list: { title: string; subtitle: string }[] | undefined;
}

const Section = ({ title, list = [] }: SectionProps) => {
  const { classes } = useStyles();

  if (!list.length) return null;

  return (
    <>
      <Typography className={classes.educationSubtitle} variant="buttonSmall">
        {title}
      </Typography>
      {list.map((item, index) => (
        <Fragment key={item.title}>
          <AvatarItem header={item.title} subHeader={item.subtitle} />
          {index < list.length - 1 && <div className={classes.line} />}
        </Fragment>
      ))}
    </>
  );
};

const CareerAndEducation = ({ mentor }: CareerAndEducationProps) => {
  const { t } = useTranslation();
  const { workExperience, educationBackground } = mentor ?? {};

  const hasCareerDetails = !!workExperience?.length;
  const hasEducationDetails = !!educationBackground?.length;

  if (!hasCareerDetails && !hasEducationDetails) return null;

  return (
    <Accordion
      title={t('v2.global.label_careerAndEducation')}
      icon={<EducationSVG />}
      content={
        <>
          <Section
            title={t('v2.global.label_workExperience')}
            list={workExperience}
          />

          <Section
            title={t('v2.global.label_university')}
            list={educationBackground}
          />
        </>
      }
    />
  );
};

export default CareerAndEducation;
