import React, { ReactNode } from 'react';
import { Dictionary } from '@reduxjs/toolkit';
import AnxietySVG from './anxiety.svg?react';
import GoalsSVG from './goals.svg?react';
import IdentitySVG from './identity.svg?react';
import OthersSVG from './other.svg?react';
import RelationshipsSVG from './relationships.svg?react';
import StressSVG from './stress.svg?react';
import BurnOutSVG from './burnout.svg?react';
import BodySVG from './body.svg?react';
import JobSVG from './job.svg?react';
import AllSVG from './all.svg?react';
import SelfEsteemSVG from './selfEsteem.svg?react';
import EmptySVG from './empty.svg?react';
import SleepSVG from './sleep.svg?react';
import RelaxationSVG from './relaxation.svg?react';
import FallbackSVG from './fallback.svg?react';
import EmotionsSVG from './emotions.svg?react';
import LeadershipSVG from './leadership.svg?react';
import RecommendationSVG from './recommendations.svg?react';

const topicIcons: Dictionary<ReactNode> = {
  recommendations: <RecommendationSVG />,
  fearsandworries: <AnxietySVG />,
  leadership: <LeadershipSVG />,
  goalsandvalues: <GoalsSVG />,
  identityanddiversity: <IdentitySVG />,
  others: <OthersSVG />,
  bodyandmind: <BodySVG />,
  burnoutanddepression: <BurnOutSVG />,
  stressmanagement: <StressSVG />,
  familypartnershipandotherrelationships: <RelationshipsSVG />,
  jobandcareer: <JobSVG />,
  selfesteem: <SelfEsteemSVG />,
  sleep: <SleepSVG />,
  stress: <RelaxationSVG />,
  emotions: <EmotionsSVG />,
  default: <FallbackSVG />,
};

export const filterIcons: Dictionary<ReactNode> = {
  ...topicIcons,
  empty: <EmptySVG />,
  allEvents: <AllSVG />,
};
