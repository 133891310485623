import useAppDrawer from 'src/app/use-app-drawer';
import { useGetLibraryItemRecommendation } from 'src/apis/library-item-api';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import LibraryDrawerContent from './library-drawer/library-drawer-content';
import useLibraryDrawerFooter from './library-drawer/use-library-drawer-footer';

interface UseLibraryDrawerProperties {
  id: string;
}

const useLibraryDrawer = ({ id }: UseLibraryDrawerProperties) => {
  const { classes } = useStyles({});
  const {
    i18n: { language },
  } = useTranslation();
  const footer = useLibraryDrawerFooter();
  const { data } = useGetLibraryItemRecommendation({ language });
  const notFound = !data?.find((item) => item?.id === id);

  return useAppDrawer({
    params: ['library-drawer'],
    value: id,
    content: <LibraryDrawerContent />,
    footer,
    forceLoadingTime: 1000,
    ctaWrapperClassName: classes.audioCtaWrapper,
    notFound,
  });
};

export default useLibraryDrawer;
