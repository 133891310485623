import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import ScrollEndIcon from 'src/assets/scroll-end-fade.svg?react';
import ScrollStartIcon from 'src/assets/scroll-end-fade-copy.svg?react';
import { useStyles } from './styles';

export const LeftArrow = () => {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleElements,
    initComplete,
  } = useContext<any>(VisibilityContext);
  const [disabled, setDisabled] = useState(
    !initComplete || (initComplete && isFirstItemVisible),
  );
  const { classes } = useStyles();

  useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
    <ScrollEndIcon
      onClick={() => scrollPrev()}
      className={clsx(disabled && classes.disabled)}
    />
  );
};

export const RightArrow = () => {
  const { isLastItemVisible, scrollNext, visibleElements } = useContext<any>(
    VisibilityContext,
  );

  const [disabled, setDisabled] = useState(
    !visibleElements.length && isLastItemVisible,
  );
  const { classes } = useStyles();

  useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (
    <ScrollStartIcon
      onClick={() => scrollNext()}
      className={clsx(disabled && classes.disabled)}
    />
  );
};
