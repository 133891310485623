import { useQuery } from '@tanstack/react-query';
import backendApi from './config/backend';
import cache from './config/cache';
import { useGetUser } from './users-api';

export type RecommendationType = 'MENTOR' | 'COMMUNITY_SESSION' | 'LIBRARY';

interface Recommendations {
  id: string;
  type: RecommendationType;
  createdAt: string;
  allRecommendations: Record<RecommendationType, string[]>;
}

const getRecommendations = async (): Promise<Recommendations> => {
  const result = await backendApi.get(`/recommendation`);
  return result.data;
};

export const useGetRecommendations = () =>
  useQuery(['recommendations'], getRecommendations, {
    staleTime: cache.ONE_HOUR,
  });

export const useGetClusterRecommendations = (): 'ON' | 'OFF' => {
  const { data: user } = useGetUser();

  const clustering = user?.lastAssessment?.WEBAPP_ONBOARDING ? 'OFF' : 'ON';

  return clustering;
};
