import { MutateOptions } from '@tanstack/react-query';
import { EvaluateResult, useFeatureFlag } from 'src/apis/feature-flag-api';

// staging config: https://feature-flag.likeminded.xyz/#/flags/6
// production config: https://feature-flag.likeminded.care/#/flags/5
const ONBOARDING_FLAG_KEY = 'ONBOARDING';

const useOnboardingExperiment = (
  mutationProperties: MutateOptions<EvaluateResult>,
) =>
  useFeatureFlag({
    flagKey: ONBOARDING_FLAG_KEY,
    fullResponse: true,
    ...mutationProperties,
  });

export default useOnboardingExperiment;
