import { makeStyles } from 'tss-react/mui';
import { colors } from 'src/style/colors';

export const useStyles = makeStyles()((theme) => ({
  emptyBox: {
    position: 'absolute',
    width: 60,
    height: 60,
    backgroundColor: 'white',
    top: 80,
    left: 30,
    [theme.breakpoints.down('sm')]: {
      top: 10,
      left: 15,
    },
  },
  loadingContainer: {
    top: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    zIndex: 1001,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  loadingInfo: {
    paddingTop: 16,
    fontWeight: 700,
    width: 220,
    color: colors.darkestGray,
    textAlign: 'center',
  },
  circle: {
    strokeLinecap: 'round',
  },
  circleBackground: {
    strokeLinecap: 'round',
    color: colors.darkGray,
  },
  progress: {
    left: 0,
    position: 'absolute',
  },
  secondaryCta: {
    fontSize: 16,
    fontWeight: 700,
    color: colors.mediumGreen,
    lineHeight: '21px',
    cursor: 'pointer',
    margin: 0,
  },
  privacyLink: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    color: theme.colors.mediumGreen,
    fontWeight: 700,
    '& path': {
      fill: theme.colors.mediumGreen,
    },
    cursor: 'pointer',
    '& svg': {
      flexShrink: 0,
    },
    '&:hover': {
      color: colors.mediumDarkGreen,
      '& path': {
        fill: colors.mediumDarkGreen,
      },
    },
  },
  footerButton: {
    width: 'fit-content',
    height: 42,
    fontSize: 14,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  buttons: {
    display: 'inline-flex',
    gap: 16,
    [theme.breakpoints.down(424)]: {
      width: '100%',
    },
  },
  share: {
    padding: 16,
    minWidth: 'fit-content',
    width: 'fit-content',
  },
  ctaWrapperClassName: {
    margin: '0 24px',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      margin: '0 24px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down(424)]: {
      justifyContent: 'center',
    },
  },
}));
