import { Trans, useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';
import { Box, Typography } from '@mui/material';
import InfoBox from 'src/components/shared/info-box/info-box';
import MuiLink from '@mui/material/Link';
import PrivacySVG from 'src/assets/privacy-banner.svg?react';
import AnonymisedDataSVG from 'src/assets/anonymised-data.svg?react';
import DataCentresSVG from 'src/assets/data-center.svg?react';
import AnonymisedAnalyticsSVG from 'src/assets/anonymised-analytics.svg?react';
import GdprSVG from 'src/assets/gdpr.svg?react';
import { privacyPolicyURL } from 'src/constants';
import { useStyles } from './styles';

const icons = {
  anonymisedData: <AnonymisedDataSVG />,
  dataCentres: <DataCentresSVG />,
  anonymisedAnalytics: <AnonymisedAnalyticsSVG />,
  gdpr: <GdprSVG />,
};

const PrivacyInfo = () => {
  const { i18n, t } = useTranslation();
  const { classes } = useStyles();

  const privacySections = [
    'anonymisedData',
    'DataCentres',
    'anonymisedAnalytics',
    'GDPR',
  ].map((item) => ({
    title: t(`v2.privacy.drawer_content_${item}_label_title`),
    description: t(`v2.privacy.drawer_content_${item}_label_subtitle`),
    illustration: icons[camelCase(item) as keyof typeof icons],
  }));

  const language = i18n.language as 'en' | 'de';

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.header}>
        <PrivacySVG />
        <Typography component="h2" className={classes.title}>
          {t('v2.privacy.drawer_label_title')}
        </Typography>
        <Typography className={classes.subtitle}>
          <Trans
            i18nKey="v2.privacy.drawer_label_subtitle"
            components={[
              <MuiLink
                href={privacyPolicyURL[language]}
                target="_blank"
                rel="noopener noreferrer"
              />,
            ]}
          />
        </Typography>
      </Box>

      <Box className={classes.privacySections}>
        {privacySections.map((item) => (
          <InfoBox {...item} className={classes.infoBox} key={item.title} />
        ))}
      </Box>
    </Box>
  );
};

export default PrivacyInfo;
