import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import backendApi from './config/backend';

type AssessmentStatus = 'COMPLETED' | 'SKIPPED';
type AssessmentType =
  | 'DIGITAL_TWIN'
  | 'PRODUCT_RECOMMENDATION'
  | 'SPARK_WELLBEING'
  | 'SPARK_ONBOARDING'
  | 'SPARK_PREFERENCES'
  | 'WEBAPP_ONBOARDING';
interface Assessment {
  id: string;
  createdAt: string;
  updatedAt: string;
  userId: string;
  type: AssessmentType;
  outcome: AssessmentStatus;
  submittedAt: string;
}

export type SubscriptionPlanOption = 'NotAvailable' | 'Limited' | 'Unlimited';
export type FormatType = 'COMMUNITY_SESSION' | 'INDIVIDUAL_SESSION' | 'LIBRARY';
type SubscriptionType = 'CREDITS' | 'UNLIMITED';

interface SubscriptionPlan {
  type: FormatType;
  option: SubscriptionPlanOption;
  limit?: number;
}
interface Subscription {
  id: string;
  active: boolean;
  companyId: string;
  createdAt: string;
  creditAmount: number;
  end: string;
  start: string;
  updatedAt: string;
  type: SubscriptionType;
  plan: SubscriptionPlan[];
}

type UserType = 'EMPLOYEE' | 'STUDENT' | 'EMPLOYEE_RELATIVE';

export interface User {
  id: string;
  name: string;
  createdAt: string;
  email: string;
  firstName: string;
  lastName: string;
  companyId: string;
  dataConsent: boolean;
  marketingConsent: boolean;
  creditBalance: number;
  subscription: Subscription;
  lastAssessment: Record<AssessmentType, Assessment>;
  marketingReminderDismissed: boolean;
  locale: string;
  recommendationsConsent: boolean;
  wellbeingConsent: boolean;
  productAssessmentReminderDismissedOn?: string;
  marketingReminderDismissedOn?: string;
  isCompanyAdmin: boolean;
  preferencesReminderDismissedOn?: string;
  wellbeingReminderConsent?: boolean;
  onboardingVideoDismissedOn?: string;
  bookingWidgetDismissedOn?: string;
  userType: UserType;
  friendsFamilyEnabled: boolean;
  usesEmployeeId: boolean;
  employeeId?: string;
}

export const getUser = async (): Promise<User> => {
  const result = await backendApi.get(`/users/me`);
  return result?.data;
};

const patchUser = async (params: Partial<User>): Promise<User> => {
  const result = await backendApi.patch(`/users/me`, params);
  return result?.data;
};

export const useGetUser = (options?: object) =>
  useQuery(['user'], getUser, { ...options });

export const usePatchUser = () => {
  const queryClient = useQueryClient();
  return useMutation(patchUser, {
    onSuccess: () => queryClient.invalidateQueries(['user']),
  });
};

const { VITE_CALL_CALENDLY_URL } = import.meta.env;

export const useGetUserCalendlyUrl = (
  baseUrl = VITE_CALL_CALENDLY_URL,
  params = {},
) => {
  const { data: user } = useGetUser();

  const url = baseUrl && new URL(baseUrl);

  const urlParams = {
    name: user?.firstName as string,
    email: user?.email,
    utm_medium: user?.id,
    ...params,
  };

  Object.entries(urlParams).forEach(([key, value]) => {
    url?.searchParams?.append(key, value);
  });

  return url;
};
