import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';
import Lottie from 'lottie-react';
import loader from 'src/assets/loader.json';
import clsx from 'clsx';
import useStyles from './styles';

interface LoaderProps {
  containerProps?: BoxProps;
  textProps?: TypographyProps;
  text?: string;
  style?: React.CSSProperties;
  fullScreen?: boolean;
  center?: boolean;
}

const Loader = ({
  containerProps,
  text,
  textProps,
  style,
  fullScreen,
  center,
}: LoaderProps) => {
  const { classes } = useStyles();

  return (
    <Box
      className={clsx({
        [classes.fullScreen]: fullScreen,
        [classes.center]: center,
      })}
      {...containerProps}
    >
      <Lottie
        loop
        animationData={loader}
        style={style}
        className={clsx({ [classes.baseWidth]: fullScreen })}
      />
      {text && (
        <Typography variant="buttonDefault" align="center" {...textProps}>
          {text}
        </Typography>
      )}
    </Box>
  );
};

export default Loader;
