import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IBookingEvent } from 'src/types';
import { getEventDate } from 'src/utils/date-utils';
import { getEventLabel } from 'src/utils/event-type';
import TimeSVG from 'src/assets/time.svg?react';
import CalendarSVG from 'src/assets/calendar.svg?react';
import { useStyles } from './styles';

interface OfferingHeaderDetailsProps {
  offering: IBookingEvent;
}

const OfferingHeaderDetails = ({ offering }: OfferingHeaderDetailsProps) => {
  const offeringDate = offering.sessions?.[0]?.startsAt;
  const { classes } = useStyles({});
  const { t } = useTranslation();

  const formattedDate = getEventDate(offeringDate);

  const eventTypeLabel = getEventLabel(offering.type);

  return (
    <>
      <Typography variant="subtitleBold" className={classes.label}>
        {eventTypeLabel}
      </Typography>

      <Box className={classes.dateContainer}>
        <CalendarSVG className={classes.icon} />
        <Typography
          variant="h4Bold"
          marginRight="16px"
          className={classes.dateTime}
        >
          {formattedDate}
        </Typography>
        <TimeSVG className={classes.icon} />
        <Typography variant="h4Bold" className={classes.dateTime}>
          {t('v2.global.label_minutes', {
            count: offering.duration,
          })}
        </Typography>
      </Box>
    </>
  );
};

export default OfferingHeaderDetails;
