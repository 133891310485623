import { useLocation } from 'react-router-dom';
import { useGetUser } from 'src/apis/users-api';
import moment from 'moment';
import { useGetBookingHistory } from 'src/apis/bookings-api';
import useLocalStorage from './use-local-storage';
import useSearchParams from './use-search-params';

const RECOMMENDATION_EMPTY_BOX = 'RECOMMENDATION_EMPTY_BOX';

export const useAppConfiguration = () => {
  const { data: user, isLoading: userLoading } = useGetUser();
  const location = useLocation();
  const { searchParams } = useSearchParams();
  const utmCampaign = searchParams.get('utm_campaign') ?? '';
  const { data: bookingHistory } = useGetBookingHistory();

  const hasOnboarding =
    !!user?.lastAssessment?.SPARK_ONBOARDING ||
    !!user?.lastAssessment?.WEBAPP_ONBOARDING;

  const withUnlimitedSessions = user?.subscription?.type === 'UNLIMITED';
  const withSideNavigation = ![
    'assessment',
    'company-admin',
    'onboarding',
  ].some((key) => location.pathname.includes(key));
  const withCredits = !location.pathname.includes('assessment');
  const withNavbarLogo = !location.pathname.includes('assessment');
  const withLogoutBtn = !['assessment', 'onboarding'].some((key) =>
    location.pathname.includes(key),
  );
  const [
    dismissedRecommendations,
    setDismissedRecommentation,
  ] = useLocalStorage<string>(RECOMMENDATION_EMPTY_BOX, '');

  const showOnboarding =
    !userLoading && !hasOnboarding && !utmCampaign.includes('EMAIL');

  const showDismissRecommendations =
    hasOnboarding && bookingHistory?.length === 0 && !dismissedRecommendations;

  const showBookingWidget = false;

  const showOnboardingSteps =
    !bookingHistory?.length ||
    bookingHistory?.every((booking) =>
      moment().isBefore(moment(booking.startsAt)),
    );

  return {
    withSideNavigation,
    withCredits,
    withNavbarLogo,
    withLogoutBtn,
    withUnlimitedSessions,
    showOnboarding,
    showDismissRecommendations,
    setDismissedRecommentation,
    showBookingWidget,
    showOnboardingSteps,
  };
};
