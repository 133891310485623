import { Route, Routes as BrowserRoutes, useLocation } from 'react-router-dom';
import { Suspense, useEffect } from 'react';
import useLocationTracker from 'src/app/use-location-tracker';
import {
  ProtectedRoute,
  ProtectedRouteWithoutLayout,
} from 'src/app/routes/protected-route';
import { useTranslation } from 'react-i18next';
import NewOnboardingAssessment from 'src/components/new-onboarding-assessment/new-onboarding-assessment';
import { useGetAuthUser } from 'src/apis/authentication-api';
import UnauthorizedLayout from 'src/layout/unauthorized-layout';
import Loader from 'src/components/shared/loader/loader';
import useRedirectLegacyRoute from './use-redirect-legacy-route';
import useRedirectProtectedRoute from './use-redirect-protected-route';
import publicRoutes from './public-routes';
import { userRoutes, companyAdminRoutes } from './private-routes';
import { routesDetails } from './utils';

export interface Route {
  path?: string;
  element?: React.ReactNode;
  index?: boolean;
  children?: Route[];
  title?: string;
  subtitle?: string;
  id?: string;
}

const renderRoutes = (routes?: Route[]): JSX.Element[] | null => {
  if (!routes?.length) return null;

  return routes.map((route) => {
    return (
      <Route
        key={`${route.path}-${route.title}`}
        path={route.path}
        element={
          route?.element && (
            <Suspense fallback={<Loader fullScreen center />}>
              {route.element}
            </Suspense>
          )
        }
        index={route?.index}
      >
        {renderRoutes(route?.children)}
      </Route>
    );
  });
};

const Routes = () => {
  const { data: authUser, isLoading } = useGetAuthUser();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const roles = authUser?.roles;
  const { VITE_NAME } = import.meta.env;
  const title = routesDetails.find(({ path }) => path === pathname)?.title;

  useRedirectProtectedRoute();
  useRedirectLegacyRoute();

  useLocationTracker();

  useEffect(() => {
    window.scrollTo(0, 0);

    document.title = title ? [t(title), VITE_NAME].join(' | ') : VITE_NAME;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (isLoading) return <Loader center fullScreen />;

  return (
    <BrowserRoutes>
      <Route element={<UnauthorizedLayout />}>
        {renderRoutes(publicRoutes)}
      </Route>

      <Route
        element={
          <ProtectedRoute
            isAllowed={roles?.includes('user')}
            redirectPath="/login"
          />
        }
      >
        {renderRoutes(userRoutes)}
      </Route>

      <Route
        element={
          <ProtectedRoute
            isAllowed={roles?.includes('company_admin')}
            redirectPath="/"
          />
        }
      >
        {renderRoutes(companyAdminRoutes)}
      </Route>
      <Route
        element={
          <ProtectedRouteWithoutLayout
            isAllowed={roles?.includes('user')}
            redirectPath="/login"
          />
        }
      >
        <Route path="/new-onboarding" element={<NewOnboardingAssessment />} />
      </Route>
    </BrowserRoutes>
  );
};

export default Routes;
