import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import theme from 'src/style/theme';
import i18n from 'src/translations/i18n';
import { Provider } from 'react-redux';
import { store } from 'src/store/store';
import { BrowserRouter } from 'react-router-dom';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { persister, queryClient } from 'src/apis/config/react-query';
import AlertProvider from './alert-provider';

const AppProviders = ({ children }: { children: React.ReactNode }) => (
  <PersistQueryClientProvider
    client={queryClient}
    persistOptions={{ persister }}
  >
    <BrowserRouter>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <AlertProvider>{children}</AlertProvider>
              <CssBaseline />
            </ThemeProvider>
          </StyledEngineProvider>
        </I18nextProvider>
      </Provider>
    </BrowserRouter>

    <ReactQueryDevtools initialIsOpen={false} />
  </PersistQueryClientProvider>
);

export default AppProviders;
