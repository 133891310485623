import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { filterIcons } from './assets/assets';
import HorizontalSelect from '../horizontal-select/horizontal-select';
import { useStyles } from './styles';

interface TopicFilterProps {
  availableTopics: Record<string, string>[];
  onChange: (keys: string[]) => void;
}

export const TopicLabel = ({ label, topicKey }: any) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Box className={classes.topicLabel}>
      {filterIcons[topicKey]}
      <Typography variant="buttonSmall">
        {label ?? t(`v2.global.chip_label_topic_${topicKey}`)}
      </Typography>
    </Box>
  );
};

const TopicFilter = ({ availableTopics = [], onChange }: TopicFilterProps) => {
  const options = ['allEvents', ...availableTopics?.map(({ key }) => key)]?.map(
    (key) => ({
      key,
      label: <TopicLabel topicKey={key} />,
    }),
  );

  return (
    <HorizontalSelect
      options={options}
      onSelect={onChange}
      name="topics"
      multiple
    />
  );
};

export default TopicFilter;
