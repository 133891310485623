import { makeStyles } from 'tss-react/mui';
import { ITheme } from 'src/style/theme';
import { colors } from 'src/style/colors';

export const useStyles = makeStyles()((theme: ITheme) => ({
  root: {
    padding: '24px 40px',
  },
  title: {
    fontWeight: 700,
  },
  subtitle: {
    paddingTop: 8,
  },
  iconWrapper: {
    verticalAlign: 'middle',
    marginTop: 8,
    marginRight: 10,
  },
  videoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 16,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
    },
  },
  expansionPanelWrapper: {
    margin: '24px 0',
  },
  expansionPanelTitle: {
    color: colors.darkestGreen,
    fontWeight: 700,
  },
  formatTagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 24,
    paddingLeft: 14,
    paddingTop: 24,
  },
  labelWrapper: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'normal',
    },
  },
  accordionBar: {
    margin: '24px 0 0 0',
  },
}));
