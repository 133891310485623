export const languageCodes = [
  'ar',
  'bg',
  'bn',
  'bs',
  'de',
  'el',
  'en',
  'es',
  'fa',
  'fr',
  'hi',
  'hr',
  'hu',
  'it',
  'lt',
  'lu',
  'mk',
  'nl',
  'pl',
  'pt',
  'ro',
  'ru',
  'sr',
  'sv',
  'ta',
  'tr',
  'uk',
  'zh',
];
