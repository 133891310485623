import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './styles';

type Variant = 'pink' | 'blue';
interface AvatarItemProps {
  header: string;
  subHeader: string;
  variant?: Variant;
}
const AvatarItem: FC<AvatarItemProps> = ({
  header,
  subHeader,
  variant = 'pink',
}) => {
  const { classes } = useStyles({ variant });

  return (
    <Box className={classes.root}>
      <Box className={classes.avatar}>{header[0]?.toUpperCase()}</Box>
      <Box className={classes.subHeaderContainer}>
        <Typography>{header}</Typography>
        <Typography className={classes.subHeader}>{subHeader}</Typography>
      </Box>
    </Box>
  );
};

export default AvatarItem;
