import { camelCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { parseJsonTranslation } from 'src/translations/i18n';

type Offering =
  | 'WORKSHOP'
  | 'WEBINAR'
  | 'EXERCISE'
  | 'EXCHANGE_GROUP'
  | 'MEDITATION';

interface FormatDetails {
  offeringFormat: Offering;
  descriptionItems: string[];
  duration: number;
  videoUrl: string;
  sessions: number;
}

const useGetOfferingFormatDetails = (): FormatDetails[] => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const durations: Record<Offering, number> = {
    WORKSHOP: 60,
    WEBINAR: 60,
    EXERCISE: 30,
    EXCHANGE_GROUP: 60,
    MEDITATION: 20,
  };
  const FORMATS: Offering[] = [
    'WORKSHOP',
    'WEBINAR',
    'EXERCISE',
    'EXCHANGE_GROUP',
    'MEDITATION',
  ];

  const VIDEOS_KEYS = {
    WORKSHOP: 'group_workshops',
    WEBINAR: 'webinars',
    EXERCISE: 'exercises',
    EXCHANGE_GROUP: 'exchange_groups',
    MEDITATION: 'meditations',
  };

  const formatDetails = FORMATS.map((offeringFormat) => {
    const videoUrl = `https://join.likeminded.care/content/videos/formats/${
      VIDEOS_KEYS[offeringFormat]
    }_${language === 'es' ? 'en' : language}/source_v2.m3u8`;

    return {
      offeringFormat,
      descriptionItems: parseJsonTranslation(
        t(
          `v2.offeringOverview.drawer_descriptionList_${camelCase(
            offeringFormat,
          )}`,
        ),
      ),
      duration: durations[offeringFormat],
      videoUrl,
      sessions: 1,
    };
  });

  return formatDetails;
};

export default useGetOfferingFormatDetails;
