import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Box, Hidden } from '@mui/material';
import GreetingBanner from 'src/components/greeting-banner/greeting-banner';
import LogoSVG from 'src/assets/likeminded-logo.svg?react';
import { useAppConfiguration } from 'src/hooks/use-app-configuration';
import { useStyles } from './styles';
import TopBarButtons from './top-bar-buttons';

const TopBar = () => {
  const { withCredits, withUnlimitedSessions } = useAppConfiguration();
  const { classes } = useStyles({ withCredits });

  return (
    <Hidden mdDown={withUnlimitedSessions}>
      <Box className={classes.topBarContainer}>
        <Box className={classes.topBarContent}>
          {withCredits ? (
            <>
              <Box component={Link} to="/" className={classes.logoWrapper}>
                <LogoSVG />
              </Box>

              <Hidden mdDown>
                <GreetingBanner />
              </Hidden>

              <Box className={classes.topBarRight}>
                <Hidden mdDown>
                  <TopBarButtons />
                </Hidden>
              </Box>
            </>
          ) : (
            <Box className={clsx(classes.logoWrapper, classes.assessmentBar)}>
              <Hidden mdDown>
                <LogoSVG />
                <TopBarButtons className={classes.buttonAssessment} />
              </Hidden>
            </Box>
          )}
        </Box>
      </Box>
    </Hidden>
  );
};

export default TopBar;
