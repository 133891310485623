import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  filterWrapper: {
    display: 'flex',
    gap: 16,
    flexWrap: 'wrap',
    marginTop: 16,
    marginBottom: 16,
  },
  topicLabel: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 8,
    '& svg': {
      width: 16,
      height: 16,
    },
  },
}));
