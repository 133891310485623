import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  horizontalSelect: {
    '& .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar': {
      display: 'none',
    },
    '& .react-horizontal-scrolling-menu--arrow-right, .react-horizontal-scrolling-menu--arrow-left': {
      position: 'absolute',
      zIndex: 1,
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& .react-horizontal-scrolling-menu--inner-wrapper': {
      alignItems: 'center',
      position: 'relative',
    },
    '& .react-horizontal-scrolling-menu--arrow-right': {
      right: 0,
    },
    '& .react-horizontal-scrolling-menu--arrow-left': {
      transform: 'scaleX(-1)',
      left: 0,
    },
  },
  topic: {
    marginRight: 16,
    display: 'inline-flex',
    gap: 8,
    borderRadius: 16,
    background: theme.colors.wildSand,
    border: `1px solid ${theme.colors.iron}`,
    padding: '8px 12px',
    cursor: 'pointer',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: theme.colors.blueGreen,
    },
  },
  active: {
    backgroundColor: theme.colors.lightGreen,
    borderColor: theme.colors.lightGreen,
  },
  disabled: {
    display: 'none',
  },
}));
