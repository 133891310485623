import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import ModalExportData from 'src/components/shared/modal/modal-export-data';
import ModalDeleteAccount from 'src/components/shared/modal/modal-delete-account';
import { imprintURL, privacyPolicyURL, termsURL } from 'src/constants';
import FooterLink from './link-item';
import { useStyles } from './styles';

const LegalLinkFooter = () => {
  const { i18n, t } = useTranslation();
  const { classes } = useStyles();
  const [exportModal, showExportModal] = useState(false);
  const [deleteModal, showDeleteModal] = useState(false);

  const language = i18n.language as 'en' | 'de';

  const links = [
    {
      label: t('v2.global.button_imprint'),
      href: imprintURL[language],
      target: '_blank',
    },
    {
      label: t('v2.global.button_privacy'),
      href: privacyPolicyURL[language],
      target: '_blank',
    },
    {
      label: t('v2.global.button_termsAndConditions'),
      href: termsURL[language],
      target: '_blank',
    },
  ];

  return (
    <Box className={classes.legalLinksWrapper}>
      {links.map((link, index: number) => {
        return (
          <React.Fragment key={link.label}>
            <FooterLink {...link} />
            {index < links.length - 1 && (
              <Typography component="span" className={classes.divider}>
                {' '}
                |{' '}
              </Typography>
            )}
          </React.Fragment>
        );
      })}
      <ModalExportData
        modal={{ isOpen: exportModal }}
        onClose={() => showExportModal(false)}
      />
      <ModalDeleteAccount
        modal={{ isOpen: deleteModal }}
        onClose={() => showDeleteModal(false)}
      />
    </Box>
  );
};

export default LegalLinkFooter;
