import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ChipList from 'src/components/shared/chip-list/chip-list';
import { analyticEvents } from 'src/utils/analytic-events';

import useSearchParams from 'src/hooks/use-search-params';
import LinkIcon from 'src/assets/link-to.svg?react';
import ExerciseIcon from 'src/assets/document.svg?react';
import TimeIcon from 'src/assets/time.svg?react';
import LibraryPlaceholderImage from 'src/assets/library-placeholder-image.svg?react';
import VideoJS from 'src/components/shared/video-player/video-player';
import DrawerRecommendedSessions from 'src/components/shared/drawer-recommended-sessions/drawer-recommended-sessions';
import useRecommendations from 'src/pages/your-recommendations/use-recommendations';
import {
  useConsumeLibraryItem,
  useGetLibraryItem,
} from 'src/apis/library-item-api';
import MentorAvatar from 'src/components/shared/mentor-avatar/mentor-avatar';
import { getLanguageLabel } from 'src/utils';
import { useDrawerStyles } from '../styles';
import useTrackLibraryItemProperties from './use-track-library-item';
import useAudioDuration from './use-audio-duration';

interface VideoDescriptionDrawerProps {
  mentorName: string;
  mentorId?: string;
  profileImage?: string;
}

const VideoDescriptionDrawer = ({
  mentorName,
  mentorId,
  profileImage,
}: VideoDescriptionDrawerProps) => {
  const { t } = useTranslation();
  return (
    <MentorAvatar
      size="medium"
      name={mentorName}
      subtitle={t('v2.global.label_yourHost')}
      profileImage={profileImage}
      mentorId={mentorId}
    />
  );
};

const LibraryDrawerContent = () => {
  const { searchParams } = useSearchParams();

  const id = searchParams.get('library-drawer');
  const { data: libraryItem } = useGetLibraryItem({ id });

  const { t } = useTranslation();
  const { classes } = useDrawerStyles({ type: libraryItem?.type });

  const { recommendedGroupSessions } = useRecommendations();
  const trackProperties = useTrackLibraryItemProperties(libraryItem);
  const { mutate: consumeLibraryItem } = useConsumeLibraryItem();

  const hasAudio = ['LIBRARY_EXERCISE', 'LIBRARY_MEDITATION'].includes(
    libraryItem?.type,
  );

  const audioDuration = useAudioDuration(hasAudio && libraryItem?.mediaUrl);

  useEffect(() => {
    if (libraryItem) {
      analyticEvents.drawerOpened(trackProperties);
    }
  }, []);

  const handleOpenPdf = () => {
    window.open(libraryItem?.pdfUrl, '_blank');
    analyticEvents.exerciseLinkOpened(trackProperties);
    consumeLibraryItem(libraryItem?.id);
  };

  const handlePlayVideo = () => {
    analyticEvents.videoPlayed(trackProperties);
    consumeLibraryItem(libraryItem?.id);
  };

  const handleVideoEnded = () => {
    analyticEvents.videoEnded(trackProperties);
  };

  const duration = {
    LIBRARY_MEDITATION: audioDuration,
    LIBRARY_EXERCISE: audioDuration,
    LIBRARY_READING: libraryItem?.readingTime,
    LIBRARY_RECORDING: libraryItem?.category === 'WEBINAR' ? 45 : 3,
  }[libraryItem?.type];

  const durationLabel = t('v2.global.label_minutes', {
    count: duration,
  });

  const sameTopicRecommendations = recommendedGroupSessions
    ?.filter((item) => item.topics.includes(libraryItem?.topicKey))
    ?.slice(0, 2);

  const chipList = [
    ...(libraryItem?.topicsLabel ?? []),
    getLanguageLabel(libraryItem?.language),
  ];

  const translatedCategory = t(
    `v2.exercises.tabView_readings_card_label_${libraryItem?.category}`,
  );

  const videoLabel =
    libraryItem?.category === 'WEBINAR'
      ? t('v2.global.label_webinar')
      : t('v2.library.offeringCard_header_videoShort');

  const label = {
    LIBRARY_MEDITATION: t('v2.global.label_meditation'),
    LIBRARY_EXERCISE: t('v2.global.label_exercise'),
    LIBRARY_READING: translatedCategory,
    LIBRARY_RECORDING: videoLabel,
  }[libraryItem?.type];

  const drawerDescription = {
    LIBRARY_MEDITATION: libraryItem?.description,
    LIBRARY_EXERCISE: libraryItem?.description,
    LIBRARY_READING: libraryItem?.description,
    LIBRARY_RECORDING: libraryItem?.category === 'WEBINAR' && (
      <VideoDescriptionDrawer
        mentorName={libraryItem?.mentorName}
        mentorId={libraryItem?.mentorId}
        profileImage={libraryItem?.mentorProfileImage}
      />
    ),
  }[libraryItem?.type];

  const webappBookingSource = {
    LIBRARY_MEDITATION: 'LIBRARY_MEDITATION_DRAWER',
    LIBRARY_EXERCISE: 'LIBRARY_EXERCISE_DRAWER',
    LIBRARY_READING: 'LIBRARY_READING_DRAWER',
    LIBRARY_RECORDING:
      libraryItem?.category === 'WEBINAR'
        ? 'LIBRARY_VIDEO_SHORT_DRAWER'
        : 'LIBRARY_VIDEO_SHORT_DRAWER',
  }[libraryItem?.type];

  return (
    <Box>
      <Typography variant="subtitleBold" className={classes.type}>
        {label}

        {typeof duration === 'number' && (
          <Box className={classes.duration}>
            <TimeIcon className={classes.timeIcon} />

            {durationLabel}
          </Box>
        )}
      </Typography>
      <Box className={classes.drawer}>
        {libraryItem?.type === 'LIBRARY_RECORDING' ? (
          <VideoJS
            url={libraryItem?.mediaUrl}
            onPlay={handlePlayVideo}
            onEnded={handleVideoEnded}
            fluid
          />
        ) : libraryItem?.imageUrl ? (
          <img
            src={libraryItem?.imageUrl}
            alt={`${libraryItem?.title}-banner`}
            className={classes.image}
          />
        ) : (
          <LibraryPlaceholderImage className={classes.placeholderImage} />
        )}
        <Box>
          <Typography variant="h2" className={classes.title}>
            {libraryItem?.title}
          </Typography>
          {drawerDescription}
        </Box>
        {!!chipList?.filter(Boolean).length && (
          <Box>
            <Typography variant="buttonSmall" className={classes.section}>
              {t('v2.global.label_details')}
            </Typography>
            <ChipList list={chipList} />
          </Box>
        )}
        {libraryItem?.type === 'LIBRARY_EXERCISE' && (
          <Box className={classes.viewExercise} onClick={handleOpenPdf}>
            <Typography variant="buttonSmall" className={classes.section}>
              {t('v2.global.label_exercise')}
            </Typography>

            <Typography
              variant="buttonDefault"
              className={classes.viewExerciseContent}
            >
              <ExerciseIcon className={classes.exerciseIcon} />
              {t(
                'v2.exercises.section_audioExercises_card_button_viewExerciseSheet',
              )}
              <LinkIcon className={classes.linkIcon} />
            </Typography>
          </Box>
        )}
        {!!sameTopicRecommendations?.length && (
          <DrawerRecommendedSessions
            title={
              <>
                {t('v2.libraryItems_drawer_groupSessions_label')}
                <Typography component="span" className={classes.labelWrapper}>
                  {chipList[0]}
                </Typography>
              </>
            }
            subtitle={t('v2.libraryItems_drawer_groupSessions_label_body')}
            list={sameTopicRecommendations}
            source="WEBAPP_LIBRARY_DRAWER"
            webappBookingSource={webappBookingSource}
          />
        )}
      </Box>
    </Box>
  );
};

export default LibraryDrawerContent;
