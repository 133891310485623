import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import TimeSVG from 'src/components/event-details/assets/Time.svg?react';
import SessionSVG from 'src/components/event-details/assets/session.svg?react';
import { useStyles } from './styles';

interface OfferingFormatTagProps {
  credits: number;
  sessions: number;
  duration: number;
}

interface TagProps {
  icon: React.ReactNode;
  label: string;
}

const Tag = ({ icon, label }: TagProps) => {
  const { classes } = useStyles();

  return (
    <Box>
      <Typography component="span" className={classes.iconWrapper}>
        {icon}
      </Typography>
      <Typography component="span" className={classes.labelWrapper}>
        {label}
      </Typography>
    </Box>
  );
};

const OfferingFormatTags: FC<OfferingFormatTagProps> = ({
  sessions,
  duration,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const sessionLabel = t('v2.global.label_session', {
    count: sessions,
  });

  return (
    <Box className={classes.formatTagsContainer}>
      <Tag
        icon={<TimeSVG />}
        label={t('v2.global.label_minutes', {
          count: duration,
        })}
      />
      <Tag icon={<SessionSVG />} label={sessionLabel} />
    </Box>
  );
};

export default OfferingFormatTags;
