import { useLocation } from 'react-router-dom';

const useTrackLibrarySource = () => {
  const location = useLocation();

  const sources = {
    'booking-confirmation': 'Confirmation',
    library: 'Library',
  };

  const source =
    Object.entries(sources).find(([key]) =>
      location.pathname.includes(key),
    )?.[1] ?? 'Home';

  return location?.state?.source || source;
};

export default useTrackLibrarySource;
