import { forwardRef } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const Input = forwardRef((props, ref) => {
  // @ts-ignore next
  const { id, error, disabled, icon, label, required, ...rest }: any = props;

  const { classes } = useStyles({
    type: rest.type,
    disabled,
    error,
  });

  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      {label && (
        <Typography
          component="label"
          variant="subtitleCaps"
          className={classes.label}
          htmlFor={id}
          color={error ? 'error' : 'initial'}
        >
          {label}
          {!required && (
            <Typography className={classes.optionalLabel} component="span">
              {` (${t('Shared.optional')})`}
            </Typography>
          )}
        </Typography>
      )}
      <div className={classes.inputContainer}>
        <input
          id={id}
          className={classes.input}
          disabled={disabled}
          // @ts-ignore next
          ref={ref}
          {...rest}
        />
        {icon}
      </div>
      {error && (
        <Typography
          component="p"
          color="error"
          variant="caption"
          className={classes.error}
        >
          {t(error.message)}
        </Typography>
      )}
    </div>
  );
});

Input.defaultProps = {
  // @ts-ignore next
  component: 'input',
  type: 'text',
  label: '',
  required: false,
  disabled: false,
  icon: null,
  error: null,
};

export default Input;
