import { makeStyles } from 'tss-react/mui';

interface StyleProps {
  open?: boolean;
  value?: string;
  dark?: boolean;
  paperWidth?: number;
}

export const useStyles = makeStyles<StyleProps>()(
  (theme, { open, dark, value, paperWidth }, classes) => ({
    filterSelect: {
      ...theme.typography.buttonSmall,
      width: 'fit-content',
      height: 34,
      color: dark ? theme.colors.white : theme.colors.darkestGreen,
      textAlign: 'center',
      boxSizing: 'border-box',
      border: `1px solid ${
        dark ? theme.colors.darkestGray : theme.colors.darkestGreen
      }`,
      borderRadius: '8px',
      gap: 8,
      '&&&': {
        backgroundColor: dark ? theme.colors.darkestGray : theme.colors.white,
      },
      '&:hover': {
        backgroundColor: dark
          ? theme.colors.darkestGreen
          : theme.colors.lightGreen,
      },
      '& div:first-child': {
        padding: '7px 36px 7px 16px',
      },
      '& svg': {
        transform: open && 'rotate(180deg)',
        pointerEvents: 'none',
        position: 'absolute',
        right: 13,
        '& path': {
          fill: dark ? theme.colors.white : theme.colors.darkestGreen,
        },
      },
    },
    bgColor: {
      backgroundColor: value ? theme.colors.white : 'transparent',
      overflow: 'hidden',
    },
    selected: {
      background: dark ? theme.colors.darkestGray : theme.colors.blueGreen,
      borderRadius: '8px',
      '&:hover': {
        backgroundColor: dark
          ? theme.colors.darkestGray
          : theme.colors.lightGreen,
      },
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center',
      padding: '16px 24px',
      '& svg': {
        marginLeft: 'auto',
        flexShrink: 0,
      },
      '&:hover': {
        backgroundColor: theme.colors.blueGreen,
      },
      [`&.${classes.selected}`]: {
        backgroundColor: `${theme.colors.white} !important`,
        '&:hover': {
          backgroundColor: `${theme.colors.blueGreen} !important`,
        },
      },
    },

    hidden: {
      display: 'none',
    },
    paper: {
      width: paperWidth ?? '250px',
    },
    checkIcon: {
      visibility: 'hidden',
    },
    show: {
      visibility: 'visible',
    },
  }),
);
