import { useLocation, useNavigate } from 'react-router-dom';
import { useAppConfiguration } from 'src/hooks/use-app-configuration';
import useOnboardingExperiment from 'src/hooks/use-onboarding-experiment';

const useRedirectToOnboarding = () => {
  const { showOnboarding } = useAppConfiguration();
  const { state } = useLocation();
  const navigate = useNavigate();

  const mutation = useOnboardingExperiment({
    onSuccess: (data) => {
      const onboardingPage =
        data?.variantKey === 'SHORT_ONBOARDING'
          ? '/new-onboarding'
          : '/onboarding';

      localStorage.setItem('ONBOARDING_VARIANT', data?.variantKey);
      if (showOnboarding && !state?.assessmentSubmitted) {
        navigate(onboardingPage);
      }
    },
  });

  return mutation;
};

export default useRedirectToOnboarding;
