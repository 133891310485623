import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  baseWidth: {
    width: '10rem',
  },
  center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 250,
  },
}));

export default useStyles;
