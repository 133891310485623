import { Box, Typography } from '@mui/material';
import MuiLink from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const useEmergencyContactInfoTranslations = () => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`v2.crisisInformation.drawer_${key}`);
  return translate;
};

const EmergencyLink = ({ url }: { url: string }) => {
  const { classes } = useStyles();

  return (
    <MuiLink
      underline="always"
      target="_blank"
      href={`http://${url}`}
      rel="noopener noreferrer"
      className={classes.link}
    >
      {url}
    </MuiLink>
  );
};
interface Hotline {
  label: string;
  phoneNumber: string;
  websiteUrl?: string;
  websiteLabel?: string;
}

interface SectionContentProps {
  country: string;
  emergencyHotline: Hotline;
}

const SectionContent = ({ item }: SectionContentProps) => {
  const { classes } = useStyles();
  const t = useEmergencyContactInfoTranslations();

  return (
    <Box className={classes.section}>
      <Box>
        <Typography className={classes.bold}>
          {t(`${item.emergencyHotline?.label}`)}
        </Typography>
        <Typography>{t(`${item.emergencyHotline?.phoneNumber}`)}</Typography>
      </Box>

      {item?.country === 'DE' ? (
        <>
          <Box>
            <Typography className={classes.bold}>
              {t('label_telefonSeelsorge')}
            </Typography>
            <Typography>{t('phoneNumber_telefonSeelsorge')}</Typography>
            <EmergencyLink url={t('website_telefonSeelsorge')} />
          </Box>
          <Box>
            <Typography className={classes.bold}>
              {t('label_violenceAgainstWomen')}
            </Typography>
            <Typography>{t('phoneNumber_violenceAgainstWomen')}</Typography>
          </Box>
          <Box>
            <Typography className={classes.bold}>
              {t('label_germanDepressionHelp')}
            </Typography>
            <EmergencyLink url={t('website_germanDepressionHelp')} />
          </Box>
        </>
      ) : (
        <>
          <Box>
            <Typography className={classes.bold}>
              {t(`${item.emergencyHotline?.websiteLabel}`)}
            </Typography>
            <EmergencyLink url={t(`${item.emergencyHotline?.websiteUrl}`)} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default SectionContent;
