// find names for colors
// https://chir.ag/projects/name-that-color/

export const groupedColors = {
  blue: {
    bayofMany: '#26468E', // new branding
    aliceBlue: '#F1F9FF', // new branding
    lightBlue: '#E7EBF0',
    mainLightBlue: '#BEDAF2',
    lightSteelBlue: '#7788A7',
    mintBlue: '#C2F1F2', // new branding
    tropicalBlue: '#BEE6F7', // new branding
    onahauBlue: '#C9EFFF', // new branding
    pattensBlue: '#D8EEFF',
    havelockBlue: '#6693DB',
    mintTulip: '#C9E7F4',
    curiousBlue: '#2496C7',
    cornflower: '#89D4E4',
    mintTulipFade: '#c9e7f41a',
  },
  gray: {
    white: '#FFFFFF', // ui6
    disabledOverlay: 'rgba(255, 255, 255, 0.7)',
    gainsboro: '#F0F1F2', // ui5
    lightGray: '#DCDDE0', // ui4
    darkGray: '#9D9FA1', // ui3
    darkestGray: '#343535', // new branding
    wildSand: '#F5F5F5',
    whisper: '#F8F7FA',
  },
  pink: {
    secondaryLightPurple: '#EDEBF6',
    lightPurple: '#D6D1EC',
    purple: '#ADA3DA',
    dullLavender: '#8B89E4',
    blueChalk: '#F0E9FF',
    mediumPurple: '#7A4DD8',
  },
  yellow: {
    midYellow: '#FFEFD9',
    orangish: '#F9A87A',
    melon: '#FFB8A9', // new branding
    burntSienna: '#ED7A61',
    dusky: '#F36363',
    tangerine: '#DE9200',
    earlyDawn: '#FFF7E5',
    peachSchnapps: '#FFDFD9',
    derby: '#FFEDD8',
  },
  green: {
    bush: '#0A231E',
    darkestGreen: '#0E352D', // new branding
    mediumDarkGreen: '#115541', // new branding
    mediumGreen: '#18795C', // new branding
    lightGreen: '#DFF0DF', // new branding
    tintedGreen: '#ABB8B6', // new branding
    blueGreen: '#ECF6EC', // new branding
    yorkGreen: '#8FC58C', // new branding
    madangGreen: '#CBF7C8', // new branding
    icebergGreen: '#D3F0E4', // new branding
    reef: '#B9FFB5',
    secondaryDarkGreen: '#1A6C78',
    chateauGreen: '#49B054',
    celadon: '#A2DD9E',
  },
  support: {
    greyGreen: '#DDEBE6',
    lightOrange: '#FFF4E5',
    lightRed: '#FCE7E7',
    skyBlue: '#E6F2FF',
    darkBlue: '#007AFF',
    darkGreen: '#00962B',
    darkOrange: '#FF9500',
    darkRed: '#E20D0D',
    porcelain: '#F3F6F6',
    edward: '#9EA1A0',
    iron: '#DCE0E0',
  },
};

export const colors = {
  ...groupedColors.blue,
  ...groupedColors.gray,
  ...groupedColors.pink,
  ...groupedColors.yellow,
  ...groupedColors.green,
  ...groupedColors.support,
};

export const buttonColors = {
  // Secondary Button
  secondaryButtonDisabled: colors.lightSteelBlue,
};

export const supportColors = {
  supportColorWarning: colors.darkOrange,
  supportColorAlert: colors.darkRed,
  supportBgWarning: colors.lightOrange,
};

export const alertColor = {
  success: colors.darkGreen,
  info: colors.darkBlue,
  warning: colors.darkOrange,
  warningInfo: colors.darkOrange,
  error: colors.darkRed,
  setting: colors.darkBlue,
  message: colors.darkBlue,
};

export const alertBackground = {
  success: colors.lightGreen,
  info: colors.skyBlue,
  warning: colors.lightOrange,
  warningInfo: colors.lightOrange,
  error: colors.lightRed,
  setting: colors.skyBlue,
  message: colors.skyBlue,
};

export const eventColors = {
  default: colors.gainsboro,
  WORKSHOP: colors.madangGreen,
  WEBINAR: colors.mintBlue,
  ONE_ON_ONE: colors.darkestGreen,
  EXCHANGE_GROUP: colors.icebergGreen,
  MEDITATION: colors.onahauBlue,
  EXERCISE: colors.tropicalBlue,
  LIBRARY_READING: colors.earlyDawn,
  LIBRARY_RECORDING: colors.blueChalk,
  LIBRARY_EXERCISE: colors.peachSchnapps,
  LIBRARY_MEDITATION: colors.derby,
};
