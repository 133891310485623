import { makeStyles } from 'tss-react/mui';
import { ITheme } from 'src/style/theme';
import imageUrl from 'src/assets/new-onboardind-side.svg';

const useStyles = makeStyles()((theme: ITheme) => ({
  illustrationSection: {
    height: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    backgroundImage: `url(${imageUrl})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  mainWrapper: {
    paddingTop: 0,
    height: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'grid',

    gridTemplateColumns: '3fr 2.5fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'auto',
    },
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  assessmentContainer: {
    display: 'flex',
    width: '905px',
    height: 'auto',
    padding: '30px 80px 0px 80px',
    flexDirection: 'column',
    gap: '96px',
    flexShrink: 0,
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },

    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      height: 'auto',
      padding: '0px',
      gap: '40px',
    },
  },
  assessmentHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '32px',
    alignSelf: 'stretch',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0px',
      padding: '0px 24px 0px 24px',
    },
  },
  assessmentFormBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    alignSelf: 'stretch',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      height: '438px',
      padding: '0px',
      flexShrink: 0,
    },
  },

  assessmentHeaderTitle: {
    fontFamily: 'ITC Souvenir Light',
    color: theme.colors.darkestGray,
    fontSize: '34px',
    fontWeight: 300,
    lineHeight: '40px',
    letterSpacing: '0.25px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      fontWeight: '300px',
      lineHeight: '26px',
      letterSpacing: '0.15px',
    },
  },
  assessmentHeaderTitleHide: {
    display: 'none',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
    alignSelf: 'stretch',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 24px 0px 24px',
    },
  },

  errorLabel: {
    color: theme.colors.darkRed,
  },
  question: {
    color: theme.colors.darkestGray,
    fontFamily: 'ITC Souvenir',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '26px',
    letterSpacing: '0.15px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 24px 0px 24px',
    },
  },
  options: {
    marginTop: '16px',
    display: 'flex',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    gap: '16px',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 24px 100px 24px',
    },
  },

  option: {
    display: 'flex',
    padding: '12px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '66px',
    border: `1px solid ${theme.colors.iron}`,
    backgroundColor: theme.colors.wildSand,
    [theme.breakpoints.up('sm')]: {
      '&:hover': {
        borderColor: theme.colors.mediumGreen,
        backgroundColor: theme.colors.blueGreen,
        cursor: 'pointer',
      },
    },
  },
  selected: {
    borderColor: theme.colors.mediumGreen,
    backgroundColor: theme.colors.blueGreen,
  },
  lastOption: {
    display: 'flex',
    alignSelf: 'stretch',
    justifyContent: 'start',
    width: '100%',
  },

  optionIcon: {
    width: '24px',
    height: '24px',
    '&:active': {
      color: theme.colors.mediumGreen,
    },
  },
  optionLabel: {
    color: theme.colors.darkestGray,
    fontWeight: 700,
    '&:active': {
      color: theme.colors.mediumGreen,
    },
  },
  labelActive: {
    color: theme.colors.mediumGreen,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    marginTop: '8px',
    marginBottom: '16px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      padding: '12px 24px',
      margin: 0,
      gap: '10px',
      borderTop: '1px solid var(--Light-UI-UI-4, #DCE0E0)',
      background: 'var(--Light-UI-UI6, #FFF)',
      boxShadow: '0px 6px 12px 0px rgba(27, 27, 28, 0.15)',
      position: 'fixed', // Fixes the container to the viewport
      bottom: 0, // Positions the container at the bottom
      left: 0, // Ensures the container spans the full width
      right: 0, // Ensures the container spans the full width
    },
  },
  assessmentProgressBar: {
    width: 200,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  topBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '24px',
    padding: '24px 80px 0px 80px',
    [theme.breakpoints.down('sm')]: {
      padding: '24px 24px 0px 24px',
    },
  },

  backButtonStyle: {
    display: 'flex',
    padding: '12px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 700,
    borderRadius: '8px',
    color: theme.colors.mediumGreen,
    gap: '10px',
    border: `2px solid ${theme.colors.mediumGreen}`,
    '&:hover': {
      cursor: 'pointer',
    },
  },

  hideBackButtonStyle: {
    display: 'none',
  },

  buttonStyle: {
    display: 'flex',
    padding: '12px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 700,
    borderRadius: '8px',
    color: theme.colors.white,
    border: `2px solid ${theme.colors.mediumGreen}`,
    backgroundColor: theme.colors.mediumGreen,
    gap: '10px',
    '&:hover': {
      backgroundColor: theme.colors.mediumDarkGreen,
      borderColor: theme.colors.mediumDarkGreen,
      cursor: 'pointer',
    },
  },
  buttonDisabled: {
    border: `2px solid ${theme.colors.tintedGreen}`,
    backgroundColor: theme.colors.tintedGreen,
    '&:hover': {
      border: `2px solid ${theme.colors.tintedGreen}`,
      backgroundColor: theme.colors.tintedGreen,
      cursor: 'not-allowed',
    },
  },
  hideButtonStyle: {
    display: 'none',
  },

  assessmentFooterDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 'auto',
    marginBottom: '16px',
    order: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '40px 24px 40px 24px',
      order: 0,
      margin: 0,
    },
  },

  assessmentFooter: {
    display: 'inline-flex',
    backgroundColor: '#F4F1FC',
    borderRadius: '8px',
    padding: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
  },
  assessmentFooterIcon: {
    width: '24px',
    height: '24px',
  },
  assessmentFooterText: {
    color: theme.colors.darkestGray,
    fontWeight: 700,
  },
  assessmentFooterTextLink: {
    color: theme.colors.mediumGreen,
    fontWeight: 700,
  },
}));

export default useStyles;
