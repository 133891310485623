/* eslint-disable react-refresh/only-export-components */
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';
import { Route } from './routes';

const Dashboard = lazy(() => import('src/pages/dashboard/dashboard'));
const YourRecommendations = lazy(
  () => import('src/pages/your-recommendations/your-recommendations'),
);
const GroupSessions = lazy(
  () => import('src/pages/group-sessions/group-sessions'),
);
const IndividualSessions = lazy(
  () => import('src/pages/individual-sessions/individual-sessions'),
);
const Library = lazy(() => import('src/pages/library/library'));
const LibraryDiscover = lazy(
  () => import('src/pages/library/library-discover'),
);
const LibraryAllContent = lazy(
  () => import('src/pages/library/library-all-content'),
);
const Account = lazy(() => import('src/pages/account/account'));
const Bookings = lazy(() => import('src/pages/bookings/bookings'));
const OnboardingAssessment = lazy(
  () => import('src/pages/onboarding-assessment/onboarding-assessment'),
);
const Assessment = lazy(() => import('src/pages/assessment/assessment'));
const Wellbeing = lazy(() => import('src/pages/wellbeing/wellbeing'));
const PaymentSuccess = lazy(
  () => import('src/components/self-pay/payment-success'),
);
const BookingConfirmation = lazy(
  () => import('src/pages/booking-confirmation/booking-confirmation'),
);
const PreAssessment = lazy(
  () => import('src/pages/pre-assessment/pre-assessment'),
);
const PageNotFound = lazy(
  () => import('src/pages/page-not-found/page-not-found'),
);
const CompanyAdminMenu = lazy(
  () => import('src/pages/company-admin-menu/company-admin-menu'),
);

export const userRoutes: Route[] = [
  {
    path: '/',
    element: <Dashboard />,
    title: 'v2.dashboard.globalNavigation_label_recommendations',
    children: [
      {
        path: '/',
        element: <YourRecommendations />,
      },
      {
        path: '/your-recommendation',
        element: <YourRecommendations />,
        title: 'v2.dashboard.globalNavigation_label_recommendations',
      },
      {
        path: '/group-sessions',
        element: <GroupSessions />,
        title: 'v2.dashboard.globalNavigation_label_groupSessions',
      },
      {
        path: '/individual-sessions',
        element: <IndividualSessions />,
        title: 'v2.dashboard.globalNavigation_label_individualSessions',
      },
      {
        path: '/library',
        element: <Library />,
        title: 'Menu.exercises',
        children: [
          {
            index: true,
            element: <LibraryAllContent />,
            title: 'Menu.exercises',
          },
          {
            path: 'discover',
            element: <LibraryDiscover />,
            title: 'Menu.exercises',
          },
          {
            path: 'all-content',
            element: <LibraryAllContent />,
            title: 'Menu.exercises',
          },
        ],
      },
    ],
  },
  {
    path: '/account',
    element: <Account />,

    title: 'Profile.account',
  },
  {
    path: '/bookings',
    element: <Bookings />,
    title: 'Menu.bookings',
  },
  {
    path: '/onboarding',
    element: <OnboardingAssessment />,
    title: 'Onboarding.title',
  },
  {
    path: '/assessment',
    element: <Assessment />,
    title: 'v2.mentalWellbeing.detailPage_label_title',
  },
  {
    path: '/wellbeing',
    element: <Wellbeing />,
    title: 'v2.mentalWellbeing.detailPage_label_title',
  },
  {
    path: '/payment-success',
    element: <PaymentSuccess />,
    title: 'Payment success',
  },
  {
    path: '/booking-confirmation',
    element: <BookingConfirmation />,
    title: 'v2.bookingConfirmation.addBooking_header_label_title',
  },
  {
    path: '/pre-assessment',
    element: <PreAssessment />,
    title: 'Complete Assessment',
  },
  {
    path: '/page-not-found',
    element: <PageNotFound />,
    title: 'Page not found',
  },
  { path: '/exercises', element: <Navigate to="/library" /> },
  { path: '*', element: <Navigate to="/page-not-found" /> },
];

export const companyAdminRoutes: Route[] = [
  {
    title:
      'v2.dashboard.adminDashboardSplitScreen_card_dashboardLabel_title_companyAdmin',
    path: '/company-admin',
    children: [
      {
        element: <CompanyAdminMenu />,
        index: true,
      },
    ],
  },
];
