import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import ShieldSVG from 'src/assets/shield.svg?react';
import MuiLink from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import LogoSVG from 'src/assets/likeminded-logo.svg?react';
import { analyticEvents } from 'src/utils/analytic-events';
import LanguageSelect from 'src/layout/language-select';
import { usePostNewOnboardingQuestions } from 'src/apis/assessment-api';
import clsx from 'clsx';
import Loader from '../shared/loader/loader';
import LinearProgressBar from '../shared/linear-progress-bar/linear-progress-bar';
import useStyles from './styles';
import useAssessmentQuestion from './assessment-question';
import AssessmentQuestionForm from './assessment-question-form';

// Temporary: These questions are for testing purposes
// TODO: change the path of new onboarding assessment

const NewOnboardingAssessment = () => {
  const [selectedOptions, setSelectedOptions] = useState({});
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const assessmentQuestion = useAssessmentQuestion();
  const [isError, setError] = useState(false);
  const totalQuestions = assessmentQuestion.length;
  const ctaBack = t('v2.global.button_label_goBack');
  const question = assessmentQuestion[currentQuestionIndex];
  const [showLoader, setShowLoader] = useState(true);
  const questionReference = question?.questionRef;
  const selectionMode = question?.selectMode;
  const isFirstQuestion = questionReference === 'QUESTION_TOPICS';
  const isLastQuestion = questionReference === 'QUESTION_GOALS';
  const ctaContinue = isLastQuestion
    ? t('v2.global.button_submit')
    : t('v2.global.button_label_continue');
  const {
    mutate: postNewOnboardingQuestions,
  } = usePostNewOnboardingQuestions();
  const selectOption = (optionValue) => {
    setError(false);
    setSelectedOptions((prevSelected) => {
      const currentSelections = prevSelected[questionReference] || [];
      if (selectionMode === 'multiple') {
        // Toggle option selection for multiple selection mode
        const newSelections = currentSelections.includes(optionValue)
          ? currentSelections.filter((value) => value !== optionValue)
          : [...currentSelections, optionValue];

        return { ...prevSelected, [questionReference]: newSelections };
      }

      if (selectionMode === 'single') {
        return { ...prevSelected, [questionReference]: [optionValue] };
      }
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 1500);

    if (isFirstQuestion) {
      analyticEvents.assessmentStarted({
        type: 'WEBAPP_ONBOARDING',
      });
    }
  }, [isFirstQuestion]);

  const selectedOption = selectedOptions[questionReference] || [];
  const isButtonDisabled = selectedOption.length === 0;

  const nextQuestion = () => {
    const hasNotSelectedOption =
      !selectedOptions[questionReference] ||
      selectedOptions[questionReference].length === 0;

    if (hasNotSelectedOption) {
      setError(true);
    } else {
      analyticEvents.assessmentStepCompleted({
        type: 'WEBAPP_ONBOARDING',
        step: questionReference,
      });
      setError(false);
      if (isLastQuestion) {
        postNewOnboardingQuestions(selectedOptions, {
          onSuccess: () => {
            navigate('/your-recommendation', {
              state: { assessmentSubmitted: true },
            });
          },
        });
        return;
      }
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  const prevQuestion = () => {
    setError(false);
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };
  const progressLabel = `${currentQuestionIndex + 1}/${totalQuestions}`;
  const progressValue = ((currentQuestionIndex + 1) / totalQuestions) * 100;

  return (
    <>
      {showLoader ? (
        <Loader containerProps={{ className: classes.loader }} />
      ) : (
        <Box className={classes.mainWrapper} component="main">
          <Box className={classes.contentWrapper}>
            <Box className={classes.topBar}>
              <Box>
                <a>
                  <LogoSVG />
                </a>
              </Box>
              <LanguageSelect />
            </Box>
            <Box className={classes.assessmentFooterDiv}>
              <Box className={classes.assessmentFooter}>
                <ShieldSVG className={classes.assessmentFooterIcon} />
                <Typography>
                  <Trans
                    className={classes.assessmentFooterText}
                    i18nKey="v2.newOnboarding.assessment_label_privacyPolicy"
                    components={[
                      <Box />,
                      <MuiLink
                        className={classes.assessmentFooterTextLink}
                        onClick={() => navigate('?privacy-info=open')}
                      />,
                    ]}
                  />
                </Typography>
              </Box>
            </Box>
            <Box className={classes.assessmentContainer}>
              <Box className={classes.assessmentHeader}>
                <LinearProgressBar
                  progressValue={progressValue}
                  progressLabel={progressLabel}
                  customClasses={{ progressBar: classes.assessmentProgressBar }}
                />
                <Typography
                  className={clsx(
                    classes.assessmentHeaderTitle,
                    !isFirstQuestion && classes.assessmentHeaderTitleHide,
                  )}
                >
                  <Trans
                    i18nKey="v2.newOnboarding.assessment_header_welcome"
                    components={[<Box />]}
                  />
                </Typography>
              </Box>
              <Box>
                <AssessmentQuestionForm
                  question={question}
                  selectOption={selectOption}
                  isFirstQuestion={isFirstQuestion}
                  isLastQuestion={isLastQuestion}
                  isError={isError}
                  ctaBack={ctaBack}
                  previousOption={prevQuestion}
                  nextOption={nextQuestion}
                  isButtonDisabled={isButtonDisabled}
                  ctaContinue={ctaContinue}
                  selectedOptions={selectedOptions}
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.illustrationSection} />
        </Box>
      )}
    </>
  );
};

export default NewOnboardingAssessment;
