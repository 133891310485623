import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import clsx from 'clsx';
import { TopicLabel } from '../shared/filters/topic-filter';
import useStyles from './styles';

interface Option {
  icon?: React.ReactNode;
  name: string;
}

interface Question {
  questionLabel: string;
  questionText: string;
  questionRef?: string;
  options: Option[];
  selectMode: 'single' | 'multiple';
}

interface AssessmentFormProps {
  question: Question;
  selectOption?: (questionIndex: number, optionName: string) => void;
  selectedOptions?: Record<number, string[]>;
  isFirstQuestion?: boolean;
  isLastQuestion?: boolean;
  isButtonDisabled?: boolean;
  isError?: boolean;
  ctaBack?: string;
  questionKey?: string;
  ctaContinue?: string;
  customClasses?: Record<string, string>;
  previousOption?: () => void;
  nextOption?: () => void;
}

const AssessmentForm: React.FC<AssessmentFormProps> = ({
  question,
  selectOption = {},
  selectedOptions = {},
  isFirstQuestion,
  isLastQuestion,
  isButtonDisabled,
  ctaBack,
  ctaContinue,
  isError,
  customClasses = {},
  previousOption,
  nextOption,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <Box
      className={clsx(
        classes.assessmentFormBody,
        customClasses.assessmentFormBody,
      )}
    >
      <Box>
        <Typography
          className={clsx(classes.label, isError && classes.errorLabel)}
        >
          {t(question.questionLabel)}
        </Typography>
        <Typography className={clsx(classes.question, customClasses.question)}>
          {t(question.questionText)}
        </Typography>
      </Box>
      <Box
        className={clsx(
          classes.options,
          isFirstQuestion && classes.firstQuestionOtions,
        )}
      >
        {question.options.map((option) => (
          <Box
            className={clsx(
              classes.option,
              isLastQuestion && classes.lastOption,
              {
                [classes.selected]: selectedOptions[
                  question.questionRef
                ]?.includes(option.value),
              },
            )}
            onClick={() => selectOption(option.value)}
          >
            <Typography
              className={clsx(classes.optionLabel, {
                [classes.labelActive]: selectedOptions[
                  question.questionRef
                ]?.includes(option.value),
              })}
              variant="caption"
            >
              <TopicLabel label={option.label} topicKey={option.topicKey} />
            </Typography>
          </Box>
        ))}
      </Box>

      <Box className={classes.buttonContainer}>
        <Typography
          className={clsx(
            classes.backButtonStyle,
            isFirstQuestion && classes.hideBackButtonStyle,
          )}
          onClick={previousOption}
        >
          {ctaBack}
        </Typography>
        <Typography
          className={clsx(
            classes.buttonStyle,
            isButtonDisabled && classes.buttonDisabled,
          )}
          onClick={nextOption}
        >
          {ctaContinue}
        </Typography>
      </Box>
    </Box>
  );
};

export default AssessmentForm;
