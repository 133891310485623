import { useGetMentors } from 'src/apis/mentors-api';
import { useGetBookingHistory } from 'src/apis/bookings-api';
import { getEventDateTime } from 'src/utils/date-utils';

const useRebookMentorData = () => {
  const { data: mentors, isLoading } = useGetMentors();
  const {
    data: bookings,
    isLoading: loadingBookingHistory,
  } = useGetBookingHistory();

  const lastOneOnOne = bookings
    ?.filter((item: any) => item.offeringType === 'ONE_ON_ONE')
    ?.reduce((a: any, b: any) => (a.bookedAt > b.bookedAt ? a : b), []);
  const lastBookedStartsAt = getEventDateTime(lastOneOnOne?.startsAt);

  const lastBookedMentor = mentors?.find(
    ({ id }) => id === lastOneOnOne?.mentorId,
  );
  const showRebookMentorCard = Boolean(lastBookedMentor?.id);

  const rebookOneOnOne = {
    ...lastBookedMentor,
    lastBookedDate: lastBookedStartsAt.date,
    lastBookedTime: lastBookedStartsAt.time,
  };

  return {
    showRebookMentorCard,
    rebookOneOnOne,
    isLoading: isLoading || loadingBookingHistory,
  };
};

export default useRebookMentorData;
