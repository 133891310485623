import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { InlineWidget } from 'react-calendly';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { mapValues } from 'lodash';
import { IBookingEvent } from 'src/types';
import { useGetUser, useGetUserCalendlyUrl } from 'src/apis/users-api';
import { useGetOfferings } from 'src/apis/offerings-api';
import DrawerHeader from 'src/components/shared/drawer-header/drawer-header';
import ChipList from 'src/components/shared/chip-list/chip-list';
import VideoJS from 'src/components/shared/video-player/video-player';
import useAfterBooking from 'src/hooks/use-after-booking';
import { Mentor } from 'src/apis/mentors-api';
import { useGetRecommendations } from 'src/apis/recommendation-api';
import useSearchParams from 'src/hooks/use-search-params';
import { useStyles } from './styles';
import MentorTags from '../mentor-card/mentor-tags';
import RequestSessions from './request-sessions';
import CareerAndEducation from './career-and-education';
import DrawerRecommendedSessions from '../shared/drawer-recommended-sessions/drawer-recommended-sessions';

interface MentorProfileProps {
  mentor: Mentor;
  onClose?: () => void;
  scrolled: boolean;
}

const MentorProfile = ({ mentor, onClose, scrolled }: MentorProfileProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { data: user } = useGetUser();
  const [mentorEvents, setMentorEvents] = useState<IBookingEvent[]>([]);
  const { data: upcomingEvents } = useGetOfferings();
  const calendlyUrlParams = {
    hide_event_type_details: '1',
    hide_gdpr_banner: '1',
    primary_color: '18795C',
  };

  const { href: calendlyUrl } = useGetUserCalendlyUrl(
    mentor?.bookingUrl,
    calendlyUrlParams,
  );

  const { data: recommendation } = useGetRecommendations();
  const location = useLocation();
  const { searchParams, getParams } = useSearchParams();
  const utmCampaign = searchParams.get('utm_campaign') ?? '';

  const filtersFromUrl = getParams([
    'language',
    'topics',
    'education',
    'gender',
  ]);

  const filters = {
    ...mapValues(filtersFromUrl, (value) => value?.split(',')),
    ...getParams(['search']),
  };

  useAfterBooking({ onClose });

  useEffect(() => {
    setMentorEvents(
      upcomingEvents?.filter(
        (event: IBookingEvent) => event.mentor?.id === mentor.id,
      ),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upcomingEvents]);

  const shouldRequestSessions = user && user.creditBalance < 3;

  return (
    <>
      <DrawerHeader
        items={[
          {
            component: t('v2.global.label_recommendedForYou'),
            className: classes.recommended,
            show: Boolean(mentor.isRecommended),
            type: 'secondary',
          },
        ]}
        scrolled={scrolled}
      />
      <Box className={classes.profileContainer}>
        <Box className={classes.headerContainer}>
          <Box className={classes.mentorWrapper}>
            {mentor?.profileImage && (
              <img
                alt="Mentor"
                className={classes.mentorImage}
                src={mentor.profileImage}
              />
            )}
            <Box className={classes.mentorTitleSection}>
              <Typography id="label_mentor_name" variant="h3">
                {mentor.name}
              </Typography>
              <Typography variant="subtitleBold" className={classes.subtitle}>
                {mentor?.education}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.sectionContainer}>
          <Typography
            className={classes.specializedLabel}
            variant="buttonSmall"
          >
            {t('v2.global.label_specialisedIn')}
          </Typography>

          <ChipList list={mentor?.focusTopicsLabels} />

          <Typography variant="captionSmall">
            {t('v2.global.label_canSupportOtherTopics')}
          </Typography>
        </Box>
        {mentor?.psychologicalApproach && (
          <Box className={classes.sectionContainer}>
            <Typography
              className={classes.specializedLabel}
              variant="buttonSmall"
            >
              {t('v2.global.label_approach')}
            </Typography>
            <ChipList list={[mentor?.psychologicalApproach]} />
          </Box>
        )}
        <Box className={classes.languageWrapper}>
          <MentorTags mentor={mentor} />
        </Box>
        <Box className={classes.descriptionWrapper}>
          <Typography className={classes.description}>
            {mentor?.description}
          </Typography>

          {mentor?.videoUrl && (
            <Box className={classes.videoContainer}>
              <VideoJS url={mentor?.videoUrl} width={276} height={140} />
            </Box>
          )}

          <CareerAndEducation mentor={mentor} />

          <Typography variant="h324Bold" className={classes.availableSessions}>
            {t(
              'v2.mentorOverview.drawer_mentorDetails_title_availability1-on1',
            )}
          </Typography>
          <Typography>
            {t(
              'v2.mentorOverview.drawer_mentorDetails_label_availability1-on1',
            )}
          </Typography>
        </Box>
        {shouldRequestSessions ? (
          <RequestSessions />
        ) : (
          mentor?.bookingUrl && (
            <Box>
              <InlineWidget
                url={calendlyUrl}
                utm={{
                  utmSource: mentor.isRecommended
                    ? recommendation?.id
                    : undefined,
                  utmTerm: JSON.stringify(filters),
                  utmCampaign,
                  ...(location.pathname === '/'
                    ? { ranking: mentor.ranking }
                    : {}),
                  utmContent: mentor.ranking,
                }}
                styles={{
                  minWidth: '320px',
                  height: '630px',
                  position: 'relative',
                }}
              />
            </Box>
          )
        )}
        {mentorEvents?.length > 0 && (
          <DrawerRecommendedSessions
            title={t(
              'v2.mentorOverview.drawer_mentorDetails_title_upcomingEvents',
            )}
            subtitle={t(
              'v2.mentorOverview.drawer_mentorDetails_label_upcomingEvents',
            )}
            list={mentorEvents}
            source="WEBAPP_PE_DRAWER"
          />
        )}
      </Box>
    </>
  );
};

export default MentorProfile;
