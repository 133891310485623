import BookingsIcon from 'src/assets/bookings.svg?react';
import ProfileIcon from 'src/assets/profile.svg?react';
import DashboardIcon from 'src/assets/dashboard.svg?react';

const navigationItems = [
  {
    title: 'Menu.home',
    path: '/',
    icon: DashboardIcon,
    children: [
      '/your-recommendation',
      '/group-sessions',
      '/library',
      '/individual-sessions',
    ],
  },
  {
    title: 'Menu.bookings',
    path: '/bookings',
    icon: BookingsIcon,
    hasNewContent: !!localStorage.getItem('HAS_NEW_BOOKING'),
  },
  {
    title: 'Menu.account',
    path: '/account',
    icon: ProfileIcon,
  },
];

export default navigationItems;
