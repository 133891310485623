import { useTranslation } from 'react-i18next';
import { languageCodes } from 'src/utils/language-code';

const useAssessmentQuestion = () => {
  const { t } = useTranslation();
  return [
    {
      questionLabel: 'v2.newOnboarding.assessment_label_eyebrow_multiSelect',
      questionText: 'v2.newOnboarding.assessment_label_question_topics',
      questionRef: 'QUESTION_TOPICS',
      selectMode: 'multiple',
      options: [
        {
          label: t('v2.global.chip_label_topic_stressmanagement'),
          topicKey: 'stress',
          value: 'stressmanagement',
        },
        {
          label: t('v2.global.chip_label_topic_leadership'),
          topicKey: 'leadership',
          value: 'leadership',
        },
        {
          label: t('v2.global.chip_label_topic_bodyandmind'),
          topicKey: 'bodyandmind',
          value: 'bodyandmind',
        },
        {
          label: t('v2.global.chip_label_topic_selfesteem'),
          topicKey: 'selfesteem',
          value: 'selfesteem',
        },
        {
          label: t(
            'v2.global.chip_label_topic_familypartnershipandotherrelationships',
          ),
          topicKey: 'familypartnershipandotherrelationships',
          value: 'familypartnershipandotherrelationships',
        },
        {
          label: t('v2.global.chip_label_topic_goalsandvalues'),
          topicKey: 'goalsandvalues',
          value: 'goalsandvalues',
        },
        {
          label: t('v2.global.chip_label_topic_fearsandworries'),
          topicKey: 'fearsandworries',
          value: 'fearsandworries',
        },
        {
          label: t('v2.global.chip_label_topic_identityanddiversity'),
          topicKey: 'identityanddiversity',
          value: 'identityanddiversity',
        },
        {
          label: t('v2.global.chip_label_topic_jobandcareer'),
          topicKey: 'jobandcareer',
          value: 'jobandcareer',
        },
      ],
    },
    {
      questionLabel: 'v2.newOnboarding.assessment_label_eyebrow_multiSelect',
      questionText: 'v2.newOnboarding.assessment_label_question_languages',
      questionRef: 'QUESTION_LANGUAGES',
      options: languageCodes.map((languageCode) => ({
        label: t(`v2.global.label_language_${languageCode}`),
        value: languageCode,
      })),
      selectMode: 'multiple',
    },
    {
      questionLabel: 'v2.newOnboarding.assessment_label_eyebrow_singleSelect',
      questionText: 'v2.newOnboarding.assessment_label_question_gender',
      questionRef: 'QUESTION_GENDER',
      options: [
        {
          label: t('v2.global.label_gender_male'),
          value: 'MALE',
        },
        {
          label: t('v2.global.label_gender_female'),
          value: 'FEMALE',
        },
        {
          label: t('v2.global.label_gender_nonBinary'),
          value: 'NON_BINARY',
        },
        {
          label: t('v2.global.chip_label_topic_others'),
          value: 'OTHER',
        },
      ],
      selectMode: 'single',
    },
    {
      questionLabel: 'v2.newOnboarding.assessment_label_eyebrow_multiSelect',
      // TODO: add the translation when added at phrase or translation file
      questionText: 'v2.newOnboarding.assessment_label_question_goals',
      questionRef: 'QUESTION_GOALS',
      options: [
        {
          label: t(
            'v2.newOnboarding.assessment_question_goals_label_improveWellbeing',
          ),
          value: 'ANSWER_IMPROVE_WELLBEING',
        },
        {
          label: t(
            'v2.newOnboarding.assessment_question_goals_label_reduceStress',
          ),
          value: 'ANSWER_REDUCE_STRESS_ANXIETY',
        },
        {
          label: t(
            'v2.newOnboarding.assessment_question_goals_label_relaxation',
          ),
          value: 'ANSWER_MINDFULNESS_RELAXATION',
        },
        {
          label: t(
            'v2.newOnboarding.assessment_question_goals_label_healthConcerns',
          ),
          value: 'ANSWER_SPECIFIC_MENTAL_HEALTH_CONCERN',
        },
        {
          label: t(
            'v2.newOnboarding.assessment_question_goals_label_educateMyself',
          ),
          value: 'ANSWER_MENTAL_HEALTH_EDUCATION',
        },
        {
          label: t(
            'v2.newOnboarding.assessment_question_goals_label_connectWithOthers',
          ),
          value: 'ANSWER_CONNECT_WITH_OTHER_PEOPLE',
        },
        {
          label: t(
            'v2.newOnboarding.assessment_question_goals_label_getInfoAboutMentalHealth',
          ),
          value: 'ANSWER_INFORMATION_SUPPORT_FAMILY_FRIEND',
        },
        {
          label: t('v2.global.chip_label_topic_others'),
          value: 'ANSWER_OTHER',
        },
      ],
      selectMode: 'multiple',
    },
  ];
};
export default useAssessmentQuestion;
