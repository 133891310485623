import { useTranslation } from 'react-i18next';
import useSearchParams from 'src/hooks/use-search-params';
import { AudioPlayer } from 'src/components/shared/audio-player/audio-player';
import { Box, Button } from '@mui/material';
import LinkIcon from 'src/assets/link-to.svg?react';
import {
  useConsumeLibraryItem,
  useGetLibraryItem,
} from 'src/apis/library-item-api';
import { analyticEvents } from 'src/utils/analytic-events';
import useTrackLibraryItemProperties from './use-track-library-item';
import { useDrawerStyles } from '../styles';

const useLibraryDrawerFooter = () => {
  const { classes } = useDrawerStyles({});
  const { searchParams } = useSearchParams();
  const id = searchParams.get('library-drawer');
  const { data: libraryItem } = useGetLibraryItem({ id });
  const { t } = useTranslation();
  const trackProperties = useTrackLibraryItemProperties(libraryItem);
  const hasAudio =
    libraryItem?.mediaUrl &&
    ['LIBRARY_EXERCISE', 'LIBRARY_MEDITATION'].includes(libraryItem?.type);
  const { mutate: consumeLibraryItem } = useConsumeLibraryItem();

  const handleAudioPlay = (
    currentTimeSeconds: number,
    durationSeconds: number,
  ) => {
    analyticEvents.audioPlayed({
      ...trackProperties,
      currentTimeSeconds,
      durationSeconds,
    });

    consumeLibraryItem(libraryItem?.id);
  };

  const handleAudioEnded = () => {
    analyticEvents.audioEnded(trackProperties);
  };

  const handleOpenLink = () => {
    window.open(libraryItem?.mediaUrl, '_blank');
    analyticEvents.readingOpened({
      ...trackProperties,
      category: libraryItem?.category,
      url: libraryItem?.pdfUrl,
    });

    consumeLibraryItem(libraryItem);
  };

  if (hasAudio) {
    return (
      <AudioPlayer
        url={libraryItem?.mediaUrl}
        onPlay={handleAudioPlay}
        onEnded={handleAudioEnded}
      />
    );
  }

  if (libraryItem?.type === 'LIBRARY_READING') {
    return (
      <Box className={classes.center}>
        <Button
          variant="contained"
          className={classes.button}
          onClick={handleOpenLink}
        >
          {t('v2.global.button_readMore')}
          <LinkIcon />
        </Button>
      </Box>
    );
  }

  return null;
};

export default useLibraryDrawerFooter;
