import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import useLocalStorage from 'src/hooks/use-local-storage';
import DownIcon from 'src/assets/chevron-down.svg?react';
import useStyles from './styles';

const BLEEDING_DEFAULT = 116;

interface WidgetProps {
  title: string;
  content: React.ReactNode;
  bleeding?: number;
  onDismiss: () => void;
  onExpand?: (open: boolean) => void;
  delay?: number;
}

const useCountdown = (seconds: number) => {
  const [timeLeft, setTimeLeft] = useState(seconds);

  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return timeLeft;
};

const Widget = ({
  title,
  content,
  bleeding = BLEEDING_DEFAULT,
  onDismiss,
  onExpand,
  delay = 0,
}: WidgetProps) => {
  const { t } = useTranslation();
  const isDesktop = window.matchMedia('(min-width: 800px)').matches;

  const time = useCountdown(delay);

  const drawerBleeding = isDesktop && time > 0 ? 0 : bleeding;

  const [expanded, setExpanded] = useLocalStorage('WIDGET_EXPANDED', false);

  const { classes } = useStyles({
    drawerBleeding,
    open: expanded,
  });

  const toggleDrawer = () => {
    if (typeof onExpand === 'function') {
      onExpand(!expanded);
    }
    setExpanded(!expanded);
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={expanded}
      onOpen={toggleDrawer}
      onClose={toggleDrawer}
      swipeAreaWidth={drawerBleeding}
      allowSwipeInChildren
      disableSwipeToOpen={false}
      SwipeAreaProps={{ className: classes.swipeArea }}
      hideBackdrop
      slotProps={{
        root: { className: classes.root },
      }}
      PaperProps={{ className: classes.paper }}
    >
      <Box className={classes.pullerWrapper}>
        <Typography
          variant="h4Bold"
          className={classes.puller}
          onClick={toggleDrawer}
        >
          {title}
          <DownIcon />
        </Typography>
      </Box>
      <Box className={classes.content}>
        {content}
        <Typography
          variant="subtitleBold"
          className={classes.dismiss}
          onClick={onDismiss}
        >
          {t('v2.global.button_label_dismiss')}
        </Typography>
      </Box>
    </SwipeableDrawer>
  );
};

export default Widget;
