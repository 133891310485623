import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  progress: {
    display: 'inline-flex',
    gap: 8,
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
  },
  progressBar: {
    width: '100%',
    alignSelf: 'center',
    height: 10,
    borderRadius: 8,
    backgroundColor: theme.colors.lightGreen,
  },
}));

export default useStyles;
