import { Navigate, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AuthorizedLayout from 'src/layout/authorized-layout';

import BookingWidget from 'src/components/booking-widget/booking-widget';
import Loader from 'src/components/shared/loader/loader';
import useRedirectToOnboarding from 'src/pages/onboarding-assessment/use-redirect-to-onboarding';
import { LikeMindedApp } from '../likeminded-app';
import AppDrawer from '../app-drawer';
import AuthProvider from '../auth-provider';

interface ProtectedRouteProps {
  isAllowed: boolean;
  redirectPath: string;
}

export const ProtectedRoute = ({
  isAllowed,
  redirectPath,
}: ProtectedRouteProps) => {
  const [load, setLoad] = useState(true);
  const mutation = useRedirectToOnboarding();

  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 1500);
  }, [mutation]);

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  if (load) {
    return <Loader fullScreen center />;
  }

  return (
    <AuthProvider>
      <AuthorizedLayout>
        <LikeMindedApp />
        <BookingWidget />
        <AppDrawer />
        <Outlet />
      </AuthorizedLayout>
    </AuthProvider>
  );
};

export const ProtectedRouteWithoutLayout = ({
  isAllowed,
  redirectPath,
}: ProtectedRouteProps) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <AuthProvider>
      <LikeMindedApp />
      <BookingWidget />
      <AppDrawer />
      <Outlet />
    </AuthProvider>
  );
};
